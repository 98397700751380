
import React, { useEffect, useState } from 'react';
import ImageSlider from "image-slider-react";
// import ImageGallery from "react-image-gallery";
// import "react-image-gallery/styles/css/image-gallery.css";
import "react-image-gallery/styles/css/image-gallery.css";
import MyImageGallery from '../../components/Imagegallery/MyImageGallery'
import ModalImage from "react-modal-image";
import './batman.css'
import FavoriteIcon from '@mui/icons-material/Favorite';
// import { TextareaAutosize } from '@material-ui/core';
import { getviewprofile, sendphotorequest, updatecount, savewishlists, getloginviewprofile, getregister, userpackagedetail } from "../action/user";
// import logo from '../../assests/images/1.jpg';

import { useParams, useNavigate } from "react-router-dom";
import config from "../../lib/config";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Freesearch() {
    const notify = () => toast("Saved Successfully!");
    // const [registers, setRegisters]=useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const [height1, setHeight] = useState(window.innerHeight);
    const { id } = useParams();
    const [post, setViewed] = useState({});
    const initial = {
        a1: "",
        a2: "",
        a3: "",
        a4: "",
        a5: "",
        a6: "",
        a7: "",
        a8: "",
        a9: "",
        a10: "",
        a11: "",
        a12: "",

        r1: "",
        r2: "",
        r3: "",
        r4: "",
        r5: "",
        r6: "",
        r7: "",
        r8: "",
        r9: "",
        r10: "",
        r11: "",
        r12: "",
    }

    const [box, setBox] = useState(initial);

    const {

        a1,
        a2,
        a3,
        a4,
        a5,
        a6,
        a7,
        a8,
        a9,
        a10,
        a11,
        a12,

        r1,
        r2,
        r3,
        r4,
        r5,
        r6,
        r7,
        r8,
        r9,
        r10,
        r11,
        r12

    } = box

    const [profileimg, setprofileimg] = useState([]);
    const [profileimg1, setprofileimg1] = useState([]);
    const [membership, setmembership] = useState(false);
    const [savenone, setsavenone] = useState("block");
    const [createwishlistbtn, setcreatewishlistbtn] = useState("inline-block");
    const [moredetailbtn, setmoredetailbtn] = useState("show");
    const [userpackages, setuserpackages] = useState({});
    // const[jathagam,setjathagam]=useState()







    const navigate = useNavigate();

    const updateDimensions = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }

    console.log("postttttttttt", post)

    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        // Pop up 의 open/close 상태에 따라
        setShowPopup(!showPopup);
    };

    const closeModal = (e) => {

        // console.log(e.target);
        if (e.target.className === "popup") {
            togglePopup();
        }
    };



    const show = () => {
        if (localStorage.user_token) {
            // console.log(Boolean(userpackages),userpackages,"userpackagesuserpackagesuserpackagesuserpackages")
            if ("package" in userpackages) {

                // 
                // console.log(typeof(localStorage.packagedetail),"hgfjhgjhfgdsjhfgdshjgjhg")
                togglePopup();
            } else {
                setmembership(false);
                window.location = "/payment";
            }
        } else {
            setmembership(false);
            window.location = "/registration";
        }
    }

    const showprofile = async () => {
        await updatecount(post._id);
        setmembership(true);
        setsavenone("none");
        togglePopup();
    }

    const savefavprofile = async () => {
        await savewishlists(post._id);
        setcreatewishlistbtn("none");
        notify()
    }

    const photorequest = async () => {
        await sendphotorequest(post._id);
    }


    useEffect(() => {

        // console.log(width);

        // console.log(height1);
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    });

    const getUserData = async () => {
        // if (localStorage.user_token) {

        // } else {

        // }


        if (localStorage.user_token) {
            // alert('gfdfgfgd')
            var test = await getloginviewprofile(id);
            console.log(test, "sss")
            // 
            console.log(test.userValue.userValue, "sdjhgfvjhsdfgjh");

            var boxitems = test.userValue.userValue;

            Allboxitemss(boxitems.a1, boxitems.a2, boxitems.a3, boxitems.a4, boxitems.a5, boxitems.a6,
                boxitems.a7, boxitems.a8, boxitems.a9, boxitems.a10, boxitems.a11, boxitems.a12,
                boxitems.r1, boxitems.r2, boxitems.r3, boxitems.r4, boxitems.r5, boxitems.r6,
                boxitems.r7, boxitems.r8, boxitems.r9, boxitems.r10, boxitems.r11, boxitems.r12)
            // Allboxitemss("a2", test.userValue.userValue.a2)

            setViewed(test.userValue.userValue);

            // const primages = test.userValue.userValue.photopath.map(e => config.API+"/images/user/"+e);
            // setprofileimg(primages);
            setprofileimg1(test.userValue.userValue.photopath[0])
            console.log(test.userValue.userValue.photopath[0], "profileimg1profileimg1profileimg1")
            if (test.userValue.userValue.photopath.length && test.userValue.userValue.photopath[0]) {
                setprofileimg(test.userValue.userValue.photopath.map(url => ({
                    original: config.API + `/images/user/${url}`,
                    // thumbnail: config.API + `/images/user/${url}`,
                    originalHeight: "200",
                    originalWidth: "200",
                    thumbnailHeight: "50",
                })))
            }
            if (test.userValue.whishlisted) {
                setcreatewishlistbtn("none");
            } else {
                setcreatewishlistbtn("inline-block");
            }

            if (test.userValue.viewlisted) {
                setmembership(true);
                setsavenone("none");
            } else {
                setsavenone("block");
            }
            var userdetail = await userpackagedetail();
            if (userdetail.success) {
                setuserpackages(userdetail.userValue)
                setmoredetailbtn("Show more details");
            } else {
                setmoredetailbtn("To purchase membership");
            }
        } else {
            var test = await getviewprofile(id);
            console.log(test, "ss")

            setViewed(test.userValue);
            setmoredetailbtn("இவர்களை தொடர்பு கொள்ள மெம்பர் ஆக பதிவு செய்ய இங்கே கிளிக் செய்யவும் ");

            if (test.userValue.photopath.length && test.userValue.photopath[0]) {
                setprofileimg(test.userValue.photopath.map(url => ({
                    original: config.API + `/images/user/${url}`,
                    // thumbnail: config.API + `/images/user/${url}`,
                    // originalHeight: "200",
                    // originalWidth: "200",
                    thumbnailHeight: "70",

                })))
            }
        }


        // var test =  await getregister();
        // 
        // console.log(test, "bdfgfdgdf");
        // setRegisters(test.userValue);

    };
    // if (post.n_marriage = "Marriage") {

    // }

    // console.log(post  , "sdjhgfvjhsdfgjh");
    const protimages = [
        "https://cdn.stocksnap.io/img-thumbs/960w/PS7M4VMB9P.jpg",
        "https://cdn.stocksnap.io/img-thumbs/960w/SVSBXHN1OU.jpg",
        "https://cdn.stocksnap.io/img-thumbs/960w/KLSTPV1LBV.jpg",
        "https://cdn.stocksnap.io/img-thumbs/960w/N06ELOLAT9.jpg",
        "https://cdn.stocksnap.io/img-thumbs/960w/YN0MX9OUSY.jpg",
        "https://cdn.stocksnap.io/img-thumbs/960w/JVBWZNCQLW.jpg",
        "https://cdn.stocksnap.io/img-thumbs/960w/LN6MZNMBUR.jpg"
    ];
    const fhkjx = protimages.map(url => ({
        original: `${url}`,
        // thumbnail: `${url}`,
        originalHeight: "200",
        originalWidth: "200",
        thumbnailHeight: "50",


    }))



    // console.log(post.a1, "uttttttt");

    function Allboxitemss(a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12,
        r1, r2, r3, r4, r5, r6, r7, r8, r9, r10, r11, r12) {


        const tamil_lang = [
            'வி',
            'கே',
            'ல',
            'செ',
            'பு',
            'ச',
            'ரா',
            'சனி',
            'சூ',
            'சு',
            'மா',
            'வ'
        ];

        // var text11 = "5/8";
        // const newarray111 = text11.split("/");




        const newarray = a1.split("/");

        const final = newarray.map(e => {
            return e = tamil_lang[e - 1];
        });
        console.log(final, "final")

        const newarray1 = a2.split("/");
        const final1 = newarray1.map(e => {
            return e = tamil_lang[e - 1];
        });
        console.log(final1, "final")

        const newarray2 = a3.split("/");
        const final2 = newarray2.map(e => {
            return e = tamil_lang[e - 1];
        });
        console.log(final2, "final")
        const newarray4 = a4.split("/");
        const final4 = newarray4.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarray5 = a5.split("/");
        const final5 = newarray5.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarray6 = a6.split("/");
        const final6 = newarray6.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarray7 = a7.split("/");
        const final7 = newarray7.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarray8 = a8.split("/");
        const final8 = newarray8.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarray9 = a9.split("/");
        const final9 = newarray9.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarray10 = a10.split("/");
        const final10 = newarray10.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarray11 = a11.split("/");
        const final11 = newarray11.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarray12 = a12.split("/");
        const final12 = newarray12.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarrayr1 = r1.split("/");
        const finalr1 = newarrayr1.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarrayr2 = r2.split("/");
        const finalr2 = newarrayr2.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarrayr3 = r3.split("/");
        const finalr3 = newarrayr3.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarrayr4 = r4.split("/");
        const finalr4 = newarrayr4.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarrayr5 = r5.split("/");
        const finalr5 = newarrayr5.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarrayr6 = r6.split("/");
        const finalr6 = newarrayr6.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarrayr7 = r7.split("/");
        const finalr7 = newarrayr7.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarrayr8 = r8.split("/");
        const finalr8 = newarrayr8.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarrayr9 = r9.split("/");
        const finalr9 = newarrayr9.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarrayr10 = r10.split("/");
        const finalr10 = newarrayr10.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarrayr11 = r11.split("/");
        const finalr11 = newarrayr11.map(e => {
            return e = tamil_lang[e - 1];
        });
        const newarrayr12 = r12.split("/");
        const finalr12 = newarrayr12.map(e => {
            return e = tamil_lang[e - 1];
        });
        var data = {}

        data['a1'] = final
        data['a2'] = final1
        data['a3'] = final2
        data['a4'] = final4
        data['a5'] = final5
        data['a6'] = final6
        data['a7'] = final7
        data['a8'] = final8
        data['a9'] = final9
        data['a10'] = final10
        data['a11'] = final11
        data['a12'] = final12

        data['r1'] = finalr1
        data['r2'] = finalr2
        data['r3'] = finalr3
        data['r4'] = finalr4
        data['r5'] = finalr5
        data['r6'] = finalr6
        data['r7'] = finalr7
        data['r8'] = finalr8
        data['r9'] = finalr9
        data['r10'] = finalr10
        data['r11'] = finalr11
        data['r12'] = finalr12
        setBox(data)
        // return final.join("/");
    }





    // console.log(post,"postpostpost")

    useEffect(() => {
        getUserData();

    }, []);





    return (

        <div className="container" style={{ marginTop: "40px" }}>
            <div className="App">
                {showPopup ? (
                    <div className="popup" onClick={(e) => closeModal(e)}>
                        {userpackages ?
                            <div className="popup_inner">
                                <h2>தற்போதைய தொகுப்புகள் : <span style={{ color: "black", fontWeight: "700" }}>{userpackages.package}</span></h2>
                                <h2>மொத்த எண்ணிக்கை : <span style={{ color: "black", fontWeight: "700" }}>{userpackages.totalcount}</span></h2>
                                <h2>பார்க்கப்பட்ட எண்ணிக்கை : <span style={{ color: "black", fontWeight: "700" }}>{userpackages.viewedcount}</span></h2>
                                <h2>மீதமுள்ள எண்ணிக்கை : <span style={{ color: "black", fontWeight: "700" }}>{userpackages.remainingcount}</span></h2>
                                <div className="buttons">
                                    <button className="btclose" onClick={showprofile}>
                                        Show
                                    </button>
                                    <button className="btclose" onClick={togglePopup}>
                                        Cancel
                                    </button>
                                </div>
                            </div> : null
                        }
                    </div>
                ) : null}
            </div>


            <a onClick={() => navigate(-1)}><button className='theri'>Go Back</button></a>

            <div className="pizza" >

                {/* <button onClick={notify}>Notify!</button> */}
                {/* <div className="col-12" >
                        <div className='dondon'>
                        <img src={config.API+"/images/user/" +post.photopath} className='allu'/>
                        </div>
                          </div>
                    <div className='col-12'>   <button href="#" className='burg'><b>புகைப்படம் பார்க்க ?</b></button>    <button onClick={savefavprofile} style={{display: createwishlistbtn}} className='drink'>Save<FavoriteIcon style={{height:'19px', width:'19px', marginLeft:'5px', marginBottom:'2px'}} /></button>
                 
                    </div> */}

                <p className='water'>{post.mname}<span className='spank'>( {post.userid} )</span></p>



                <div className="row" style={width <= 774 ? { height: 'auto' } : { height: '360px' }}>

                    <div className="col-md-12 sm-12">

                        <div className='container'>
                            <div className="row " >

                                <div className="col-md-6 col-lg-4" >
                                    <div className='dontdon'>
                                        {profileimg.length ?
                                            // <MyImageGallery images={profileimg} showFullscreenButton={true} showPlayButton={false} />
                                            <img className="logo viewphotoz" style={{ marginTop: "15px" }} src={config.API + "/images/user/" + post.photopath[0]} />

                                            : <img className='donzz' src={"sex" in post && post.sex == "Male" ? require('../../assests/images/man.png') : require('../../assests/images/woman.png')} />

                                        }
                                        {/* <p className='fullscreen'>Click the Box For FullScreen</p> */}
                                    </div>


                                    {/* {localStorage.user_token ? <div>
                                        <button onClick={photorequest} className='burg' style={{ marginTop: "10px" }}><b>புகைப்படம் பார்க்க ?</b></button>    <button onClick={savefavprofile} style={{ display: createwishlistbtn }} className='drink'>Save<FavoriteIcon style={{ height: '19px', width: '19px', marginLeft: '5px', marginBottom: '2px' }} /></button>
                                    </div> : null} */}
                                </div>



                                <div className="col-md-6 col-lg-4 content">
                                    <table className='AnDroid' >
                                        <tbody>
                                            <tr >
                                                <td className='billa'>பெயர்</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.mname}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>பாலினம்</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.sex == 'Male' ? "ஆண்" : "பெண்"}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>வயது </td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.age = new Date().getFullYear() - post.year + "" + " Yrs"}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>கல்வித்தகுதி</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.educ}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>ஜாதி</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'> {post.castename}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>உட்பிரிவு</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.subcaste}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>மாவட்டம் </td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'> {post.city}</td>
                                            </tr>

                                            <tr   >
                                                <td className='billa'>திருமண நிலை</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.maritalname == "Marriage" ? "முதல்மணம்" : "மறுமணம்"}</td>
                                            </tr>
                                            {post.maritalname == "Marriage" ?
                                                <tr className="hide">
                                                    <td className='billa'>குழந்தைகள்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.children}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td className='billa'>குழந்தைகள்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.children}</td>
                                                </tr>}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-md-6 col-lg-4 content">
                                    <table className="sans" >
                                        <tbody>
                                            <tr>
                                                <td className='billa'>பிறந்த தேதி</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.dob = post.date + "-" + post.month + "-" + post.year}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>உயரம்</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.height}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>நிறம்</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.colorname}</td>
                                            </tr>

                                            <tr>
                                                <td className='billa'>வேலை</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.occup}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>மாத வருமானம்</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>	{post.income}</td>
                                            </tr>
                                            {post.maritalname == "Marriage" ?
                                                <tr className="hide">
                                                    <td className='billa'>மறுமணம் காரணம்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.n_marriage}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td className='billa'>மறுமணம் காரணம்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.n_marriage}</td>
                                                </tr>}
                                            {post.maritalname == "Marriage" || post.children == "No" ?
                                                <tr className="hide">
                                                    <td className='billa'>குழந்தைகள் விபரம்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.child_detail}</td>
                                                </tr>
                                                :
                                                <tr>
                                                    <td className='billa'>குழந்தைகள் விபரம்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.child_detail}</td>
                                                </tr>}

                                            <tr>
                                                <td className='billa'>ஜாதி தடை இல்லை</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>	{post.castenone}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa'>மாற்றுத்திறனாளி</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'> {post.handireason == "" ? "இல்லை" : 'ஆம்'}</td>
                                            </tr>
                                            {post.handireason ?
                                                <tr>
                                                    <td className='billa'>விபரம்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>	{post.handireason}</td>
                                                </tr>
                                                : null}

                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="centerFruit" >
                <div className='container'>

                    <div className="row" style={width <= 774 ? { height: 'auto' } : { height: 'auto' }}>

                        <div className="col-md-12 sm-12 ">

                            <p className='name1'>குடும்ப விவரங்கள்</p>

                            <div className="row " >

                                <div className="col-md-6 ">

                                    <table className='Boomer' >


                                        <tbody><tr>
                                            <td className='billa1'>தந்தையின் பெயர்</td>
                                            <td className='kuruvi1'>:</td>
                                            <td className='alan1'>{post.father_name}</td>
                                        </tr>
                                            <tr>
                                                <td className='billa1'>தாய் பெயர்</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'>{post.mother_name}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>தந்தையின் வேலை</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'>{post.dad_work}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>தாயின் வேலை</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'>{post.mom_work}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>சகோதரர்கள்(இவரையும் சேர்த்து)</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'> {post.brother}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>திருமணமான சகோதரர்கள்</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'> {post.m_brother}</td>
                                            </tr>

                                        </tbody></table>
                                </div>
                                <div className="col-md-6 content">
                                    <table className='Boomer' >
                                        <tbody>
                                            <tr>
                                                <td className='billa1'>சகோதரிகள்(இவரையும் சேர்த்து)</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'>{post.sister}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>திருமணமான சகோதரிகள்</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'>{post.m_sister}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>பதிவுசெய்பவர்</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'>{post.regis}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>பூர்வீகம்</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'>{post.puriveaam}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>சொத்துக்கள்</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'> {post.peroparty}</td>
                                            </tr>
                                            <tr>
                                                <td className='billa1'>சீர் வருசை</td>
                                                <td className='kuruvi1'>:</td>
                                                <td className='alan1'> {post.dever} </td>
                                            </tr>


                                        </tbody>
                                    </table>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>







            {membership ?
                <div>



                    <div className="centerFruit" >

                        <div className="row" style={width <= 774 ? { height: 'auto' } : { height: 'auto' }}>
                            <div className="col-md-12 sm-12">

                                <p className='name1'>ஜாதக விவரங்கள்</p>

                                <div className="row " >

                                    <div className="col-md-6 ">

                                        <table className='Boomer' >


                                            <tbody><tr>
                                                <td className='billa'>நட்சத்திரம்</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.starname}</td>
                                            </tr>
                                                <tr>
                                                    <td className='billa'>ராசி</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.zodiacname}</td>
                                                </tr>
                                                {post.dhosamname ?
                                                    <tr>
                                                        <td className='billa'>தோஷம்</td>
                                                        <td className='kuruvi'>:</td>
                                                        <td className='alan'>{post.dhosamname}</td>
                                                    </tr>
                                                    : null}
                                                <tr>
                                                    <td className='billa'>லக்னம்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.gothra}</td>
                                                </tr>
                                                <tr>
                                                    <td className='billa'>திசை இருப்பு</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.directionname}</td>
                                                </tr>


                                            </tbody></table>
                                    </div>
                                    <div className="col-md-6 content">
                                        <table className='Boomer' >
                                            <tbody>
                                                <tr>
                                                    <td className='billa'>வருடம்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'> {post.s_year}</td>
                                                </tr>
                                                <tr>
                                                    <td className='billa'>மாதம்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'> {post.s_month}</td>
                                                </tr>
                                                <tr>
                                                    <td className='billa'>நாள்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.s_day}</td>
                                                </tr>
                                                <tr>
                                                    <td className='billa'>பாதம்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.patham}</td>
                                                </tr>
                                                {/* <tr>
            <td className='billa'>தங்களை பற்றி</td>
            <td className='kuruvi'>:</td>
            <td className='alan'>{post.comment}</td>
        </tr> */}

                                            </tbody>
                                        </table>


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                    <div className style={{ marginTop: '30px' }}>
                        <img src={require('../../assests/images/chat_new.png')} className="img-responsive" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                        <div className="form-group table-responsive" style={{ fontSize: "17px" }}>
                            <table className="rassi-table" style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '50px' }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <textarea
                                                value={(r1)}
                                                id="r1"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(r2)}
                                                id="r2"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(r3)}
                                                id="r3"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(r4)}
                                                id="r4"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <div></div>
                                        <td>
                                            <textarea
                                                value={(a1)}
                                                id="a1"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(a2)}
                                                id="a2"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(a3)}
                                                id="a3"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(a4)}
                                                id="a4"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <textarea
                                                value={(r5)}
                                                id="r5"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td colspan="2" rowspan="2" width="124" align="center">
                                            <img src={require('../../assests/images/raaasi.png')} width={112} height={45} />
                                        </td>
                                        <td><textarea
                                            value={(r6)}
                                            id="r6"
                                            readonly
                                            style={{ textAlign: "center" }}
                                            minRows={3}
                                            Cols={8}
                                            formControlProps={{
                                                fullWidth: true,
                                            }} />
                                        </td>
                                        <div style={{ marginLeft: "15px" }}></div>
                                        <td>
                                            <textarea
                                                value={(a5)}
                                                id="a5"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td rowspan="2" colspan="2" width="133" align="center">
                                            <img src={require('../../assests/images/amsam.png')} width={112} height={45} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(a6)}
                                                id="a6"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <textarea
                                                value={(r7)}
                                                id="r7"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(r8)}
                                                id="r8"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <div></div>
                                        <td>
                                            <textarea
                                                value={(a7)}
                                                id="a7"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td width="57" height="63" align="center">
                                            <textarea
                                                value={(a8)}
                                                id="a8"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <textarea
                                                value={(r9)}
                                                id="r9"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(r10)}
                                                id="r10"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(r11)}
                                                id="r11"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(r12)}
                                                id="r12"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <div></div>
                                        <td>
                                            <textarea
                                                value={(a9)}
                                                id="a9"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(a10)}
                                                id="a10"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(a11)}
                                                id="a11"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(a12)}
                                                id="a12"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="centerFruit" >

                        <div className="row" style={width <= 774 ? { height: 'auto' } : { height: 'auto' }}>
                            <div className="col-md-12 sm-12 ">

                                <p className='name1'>முகவரி விவரங்கள்</p>

                                <div className="row " >

                                    <div className="col-md-12 ">

                                        <table className='Boomer' >


                                            <tbody><tr>
                                                <td className='billa'>கைபேசி எண்</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.phone_number}</td>
                                            </tr>
                                                <tr>
                                                    <td className='billa'>முகவரி</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.address}</td>
                                                </tr>
                                                <tr>
                                                    <td className='billa'>இமெயில் முகவரி</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.email_id}</td>
                                                </tr>

                                            </tbody></table>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

















                </div>

                :

                <div>
                    <div className="centerFruit" >

                        <div className="row" style={width <= 774 ? { height: 'auto' } : { height: 'auto' }}>
                            <div className="col-md-12 sm-12">

                                <p className='name1'>ஜாதக விவரங்கள்</p>

                                <div className="row " >

                                    <div className="col-md-6 ">

                                        <table className='Boomer' >


                                            <tbody><tr>
                                                <td className='billa'>நட்சத்திரம்</td>
                                                <td className='kuruvi'>:</td>
                                                <td className='alan'>{post.starname}</td>
                                            </tr>
                                                {post.dhosamname ?
                                                    <tr>
                                                        <td className='billa'>தோஷம்</td>
                                                        <td className='kuruvi'>:</td>
                                                        <td className='alan'>{post.dhosamname}</td>
                                                    </tr>
                                                    : null}
                                                <tr>
                                                    <td className='billa'>ராசி</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.zodiacname}</td>
                                                </tr>
                                                <tr>
                                                    <td className='billa'>லக்னம்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.gothra}</td>
                                                </tr>
                                                <tr>
                                                    <td className='billa'>திசை இருப்பு</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.directionname}</td>
                                                </tr>


                                            </tbody></table>
                                    </div>
                                    <div className="col-md-6 content">
                                        <table className='Boomer' >
                                            <tbody>
                                                <tr>
                                                    <td className='billa'>வருடம்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'> {post.s_year}</td>
                                                </tr>
                                                <tr>
                                                    <td className='billa'>மாதம்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'> {post.s_month}</td>
                                                </tr>
                                                <tr>
                                                    <td className='billa'>நாள்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.s_day}</td>
                                                </tr>
                                                <tr>
                                                    <td className='billa'>பாதம்</td>
                                                    <td className='kuruvi'>:</td>
                                                    <td className='alan'>{post.patham}</td>
                                                </tr>
                                                {/* <tr>
                                    <td className='billa'>தங்களை பற்றி</td>
                                    <td className='kuruvi'>:</td>
                                    <td className='alan'>{post.comment}</td>
                                </tr> */}

                                            </tbody>
                                        </table>


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                    <div className style={{ marginTop: '30px' }}>
                        <img src={require('../../assests/images/chat_new.png')} className="img-responsive" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                        <div className="form-group table-responsive" style={{ fontSize: "17px" }}>
                            <table className="rassi-table" style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '50px' }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <textarea
                                                value={(r1)}
                                                id="r1"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(r2)}
                                                id="r2"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(r3)}
                                                id="r3"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(r4)}
                                                id="r4"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <div></div>
                                        <td>
                                            <textarea
                                                value={(a1)}
                                                id="a1"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(a2)}
                                                id="a2"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(a3)}
                                                id="a3"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(a4)}
                                                id="a4"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <textarea
                                                value={(r5)}
                                                id="r5"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td colspan="2" rowspan="2" width="124" align="center">
                                            <img src={require('../../assests/images/raaasi.png')} width={112} height={45} />
                                        </td>
                                        <td><textarea
                                            value={(r6)}
                                            id="r6"
                                            readonly
                                            style={{ textAlign: "center" }}
                                            minRows={3}
                                            Cols={8}
                                            formControlProps={{
                                                fullWidth: true,
                                            }} />
                                        </td>
                                        <div style={{ marginLeft: "15px" }}></div>
                                        <td>
                                            <textarea
                                                value={(a5)}
                                                id="a5"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td rowspan="2" colspan="2" width="133" align="center">
                                            <img src={require('../../assests/images/amsam.png')} width={112} height={45} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(a6)}
                                                id="a6"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <textarea
                                                value={(r7)}
                                                id="r7"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(r8)}
                                                id="r8"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <div></div>
                                        <td>
                                            <textarea
                                                value={(a7)}
                                                id="a7"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td width="57" height="63" align="center">
                                            <textarea
                                                value={(a8)}
                                                id="a8"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <textarea
                                                value={(r9)}
                                                id="r9"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(r10)}
                                                id="r10"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(r11)}
                                                id="r11"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(r12)}
                                                id="r12"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <div></div>
                                        <td>
                                            <textarea
                                                value={(a9)}
                                                id="a9"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(a10)}
                                                id="a10"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(a11)}
                                                id="a11"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                        <td>
                                            <textarea
                                                value={(a12)}
                                                id="a12"
                                                readonly
                                                style={{ textAlign: "center" }}
                                                minRows={3}
                                                Cols={8}
                                                formControlProps={{
                                                    fullWidth: true,
                                                }} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>






                </div>
            }

            <button onClick={show} className='burg' style={{ display: savenone }}>{moredetailbtn}<FavoriteIcon style={{ height: '19px', width: '19px', marginLeft: '5px', marginBottom: '2px', marginTop: "0px" }} /></button>

        </div>











    )
}

export default Freesearch;