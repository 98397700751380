import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from "@material-ui/core/TextField";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CustomInput from "./../CustomInput/CustomInput";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextareaAutosize } from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Tabp from '../../ui/tabpanel'
import Robot from '../Robot/robot'
import AOS from 'aos';
import CancelIcon from '@mui/icons-material/Cancel';
import swal from "sweetalert";

import isEmpty from "../../lib/isEmpty";

import {
    addregister, getreligionlist, getregislist, getcastelist,
    getmaritallist, getcolorlist, getstarlist, getzodiaclist, getdirectionlist, getdistrictslist, getdhosamlist, getsitedata
} from "../action/user";


const style = {

    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #ff6ed',
    boxShadow: 24,
    p: 4,
    borderRadius: '25px'


};

const useStyles = makeStyles((theme) => ({
    InputLabel: {
        color: "rgb(0,0,0)",
        fontFamily: 'Source Sans Pro'
    },
    textDanger: {
        color: "#950695",
        fontSize: "13px",
        float: "left"
    },
    formControl: {

        margin: theme.spacing(1),
        minWidth: 120

    },
    //   MuiAutocomplete:{
    //     fontSize:"16px"
    //   },  




}));

// toaster config
toast.configure();
let toasterOption = {
    position: "bottom center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
};

const initialFormValue = {
    // personal detail
    phone_number: "",
    mname: "",
    religion: { title: 'Hindu', value: '61fe0d29b9938726b92dcb44' },
    subcaste: "",
    height: "",
    educ: "",
    w_place: "",
    different: { title: "இல்லை", value: "No" },
    handireason: "",
    bestgod: "",
    date: "",
    month: "",
    year: "",
    sex: "",
    dhosamname: "",
    castename: "",
    birth_time: "",
    occup: "",
    income: "",
    children: "",
    child_detail: "",
    colorname: "",
    maritalname: { title: "முதல் மணம்", value: "Marriage" },
    n_marriage: "",
    castenone: "",
    // Family detail
    father_name: "",
    mother_name: "",
    dad_work: "",
    mom_work: "",
    brother: "",
    m_brother: "",
    sister: "",
    m_sister: "",
    regis: "",
    puriveaam: "",
    livesity: "",
    dever: "",
    peroparty: "",
    pob: "",

    // horscope detail

    starname: "",
    patham: "",
    zodiacname: "",
    gothra: "",
    directionname: "",
    s_year: "",
    s_month: "",
    s_day: "",
    r1: "",
    r2: "",
    r3: "",
    r4: "",
    r5: "",
    r6: "",
    r7: "",
    r8: "",
    r9: "",
    r10: "",
    r11: "",
    r12: "",
    a1: "",
    date: "",
    month: "",
    year: "",
    a2: "",
    a3: "",
    a4: "",
    a5: "",
    a6: "",
    a7: "",
    a8: "",
    a9: "",
    a10: "",
    a11: "",
    a12: "",
    //contact details

    address: "",
    photoshow: "",
    city: "",
    mobile_number: "",
    email_id: "",
    comment: "",

};



export default function UserProfile() {
    const classes = useStyles();
    const history = useNavigate();
    // const dispatch = useDispatch();
    const [userdet, setUser] = useState();
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});
    const [idpass, setidpass] = useState('61fe0d29b9938726b92dcb44')
    const [dhosams, setdhosam] = useState();
    const [totalcount, settotalcount] = useState(0)
    const [options, setoption] = useState();
    const [colors, setcolor] = useState();

    const [religions, setreligion] = useState([]);
    const [castes, setcaste] = useState();
    // const [maritals, setmarital] = useState();
    const [stars, setstar] = useState();
    const [zodiacs, setzodiac] = useState();
    const [directions, setdirection] = useState();
    const [districts, setdistricts] = useState();

    const [childdetail, setchild] = useState("none");
    const [remarriage, setUnmarried] = useState("none");

    const [handyreason, setdifferent] = useState("none");
    const [castenone, setcastenone] = useState(false);

    const styles = {
        floatingLabelFocusStyle: {
            color: "red"
        }
    }

    const handleFile = (event) => {
        event.preventDefault();

        // console.log(event.target.files[0]);
        const { id, files } = event.target;
        //settmpupimagefront(URL.createObjectURL(event.target.files[0]));
        let formData = { ...formValue, ...{ [id]: files[0] } };
        setFormValue(formData);
        //setValidateError(formData)
    };
    // 
    // console.log("ghfdghfdhgfdfgdhgfhdhgfhfgd",localStorage.user_token);

    // function
    const onChange = (e) => {
        e.preventDefault();
        // var testAbdul = convertThis(event,numchar);

        // console.log(e.target);
        const { id, value } = e.target;
        let formData = { ...formValue, ...{ [id]: value } };
        setFormValue(formData);

        // console.log(formValue);
        //setValidateError(formData)
    };
    const dayoption = [];
    for (let i = 1; i <= 31; i++) {
        dayoption.push(i);
    }

    const monthoption = [];
    for (let i = 1950; i <= 2030; i++) {
        monthoption.push(i);
    }

    const {
        phone_number,
        mname,
        religion,
        subcaste,
        height,
        educ,
        w_place,
        different,
        handireason,
        bestgod,
        date,
        month,
        year,
        sex,
        castename,
        birth_time,
        occup,
        income,
        children,
        child_detail,
        colorname,
        maritalname,
        n_marriage,
        // family detail
        father_name,
        mother_name,
        dad_work,
        mom_work,
        brother,
        m_brother,
        sister,
        m_sister,
        regis,
        puriveaam,
        livesity,
        dever,
        peroparty,
        pob,

        //Horscope DetailssetRegisterfilters
        starname,
        patham,
        zodiacname,
        gothra,
        directionname,
        s_year,
        s_month,
        s_day,
        r1,
        r2,
        r3,
        r4,
        r5,
        r6,
        r7,
        r8,
        r9,
        r10,
        r11,
        r12,
        a1,
        a2,
        a3,
        a4,
        a5,
        a6,
        a7,
        a8,
        a9,
        a10,
        a11,
        a12,

        //contact detail
        address,
        city,
        photoshow,
        photopath,
        jathgampic,
        mobile_number,
        email_id,
        comment,
        age,
        dhosamname

    } = formValue;

    function changenumbertojathagam(text) {
        const tamil_lang = [
            'வி',
            'கே',
            'ல',
            'செ',
            'பு',
            'ச',
            'ரா',
            'சனி',
            'சூ',
            'சு',
            'மா',
            'வ'
        ];
        const newarray = text.split("/");
        const final = newarray.map(e => {
            return e = tamil_lang[e - 1];
        });
        //    
        // console.log(final.join("/"), "bdfgfdgdf");
        return final.join("/");
    }

    const handleFormSubmit = async (e) => {
        //
        // console.log("saran");
        e.preventDefault();

        // console.log(formValue);
        let reqData = {
            phone_number,
            mname,
            religion: religion.value,
            subcaste,
            height,
            educ,
            w_place,
            different: different.value,
            handireason,
            bestgod,
            date,
            month,
            year,
            sex,
            castename,
            birth_time,
            occup,
            income,
            children,
            child_detail,
            colorname,
            maritalname: maritalname.value,
            n_marriage,
            castenone: castenone ? "Yes" : "No",
            // family detail
            father_name,
            mother_name,
            dad_work,
            mom_work,
            brother,
            m_brother,
            sister,
            m_sister,
            regis,
            puriveaam,
            livesity,
            dever,
            peroparty,
            pob,
            //  Horscope Details

            starname,
            patham,
            zodiacname,
            gothra,
            directionname,
            s_year,
            s_month,
            s_day,
            r1,
            r2,
            r3,
            r4,
            r5,
            r6,
            r7,
            r8,
            r9,
            r10,
            r11,
            r12,
            a1,
            a2,
            a3,
            a4,
            a5,
            a6,
            a7,
            a8,
            a9,
            a10,
            a11,
            a12,


            //contact detailshandleO
            city,
            photoshow: "Yes",
            photopath,
            jathgampic,
            mobile_number,
            email_id,
            comment,
            address,


            dhosamname
            //age = new Date().getFullYear()-year,
        };


        console.log("req", reqData);
        let { error } = await addregister(reqData);

        // console.log("error", error);
        if (isEmpty(error)) {
            toast.success("Submitted successfully", toasterOption);
            // history.push("/");
            // window.location = "/";
            history("/");
            swal("Registered successfully");
        } else {
            setValidateError(error);
            swal("அனைத்து தேவையான விவரங்களையும் பதிவு செய்யவும்");
        }
    }

    const brothers = [
        { title: "0", value: "0" },
        { title: "1", value: "1" },
        { title: "2", value: "2" },
        { title: "3", value: "3" },
        { title: "4", value: "4" },
        { title: "5", value: "5" },
        { title: "6", value: "6" },
        { title: "7", value: "7" },
        { title: "8", value: "8" },
        { title: "9", value: "9" },
        { title: "10", value: "10" },
        { title: "11", value: "11" },
        { title: "12", value: "12" },
    ];

    const mbrothers = [
        { title: "0", value: "0" },
        { title: "1", value: "1" },
        { title: "2", value: "2" },
        { title: "3", value: "3" },
        { title: "4", value: "4" },
        { title: "5", value: "5" },
        { title: "6", value: "6" },
        { title: "7", value: "8" },
        { title: "9", value: "9" },
        { title: "10", value: "10" },
        { title: "11", value: "11" },
        { title: "12", value: "12" },
    ];

    const sisters = [
        { title: "0", value: "0" },
        { title: "1", value: "1" },
        { title: "2", value: "2" },
        { title: "3", value: "3" },
        { title: "4", value: "4" },
        { title: "5", value: "5" },
        { title: "6", value: "6" },
        { title: "7", value: "7" },
        { title: "8", value: "8" },
        { title: "9", value: "9" },
        { title: "10", value: "10" },
        { title: "11", value: "11" },
        { title: "12", value: "12" },
    ];

    const msisters = [
        { title: "0", value: "0" },
        { title: "1", value: "1" },
        { title: "2", value: "2" },
        { title: "3", value: "3" },
        { title: "4", value: "4" },
        { title: "5", value: "5" },
        { title: "6", value: "6" },
        { title: "7", value: "7" },
        { title: "8", value: "8" },
        { title: "9", value: "9" },
        { title: "10", value: "10" },
        { title: "11", value: "11" },
        { title: "12", value: "12" },
    ];

    const heights = [
        { title: "4ft 5in", value: "4ft 5in" },
        { title: "4ft 6in", value: "4ft 6in" },
        { title: "4ft 7in", value: "4ft 7in" },
        { title: "4ft 8in", value: "4ft 8in" },
        { title: "4ft 9in", value: "4ft 9in" },
        { title: "4ft 10in", value: "4ft 10in" },
        { title: "4ft 11in", value: "4ft 11in" },
        { title: "5ft", value: "5ft" },
        { title: "5ft 1in", value: "5ft 1in" },
        { title: "5ft 2in", value: "5ft 2in" },
        { title: "5ft 3in", value: "5ft 3in" },
        { title: "5ft 4in", value: "5ft 4in" },
        { title: "5ft 5in", value: "5ft 5in" },
        { title: "5ft 6in", value: "5ft 6in" },
        { title: "5ft 7in", value: "5ft 7in" },
        { title: "5ft 8in", value: "5ft 8in" },
        { title: "5ft 9in", value: "5ft 9in" },
        { title: "5ft 10in", value: "5ft 10in" },
        { title: "5ft 11in", value: "5ft 11in" },
        { title: "6ft", value: "6ft" },
        { title: "6ft 1in", value: "6ft 1in" },
        { title: "6ft 2in", value: "6ft 2in" },
        { title: "6ft 3in", value: "6ft 3in" },
        { title: "6ft 4in", value: "6ft 4in" },
        { title: "6ft 5in", value: "6ft 5in" },
        { title: "6ft 6in", value: "6ft 6in" },
        { title: "6ft 7in", value: "6ft 7in" },
        { title: "6ft 8in", value: "6ft 8in" },
        { title: "6ft 9in", value: "6ft 9in" },
        { title: "6ft 10in", value: "6ft 10in" },
        { title: "6ft 11in", value: "6ft 11in" },
        { title: "7ft", value: "7ft" },
    ];

    const sexch = [
        { title: "ஆண்", value: "Male" },
        { title: "பெண்", value: "Female" },
    ];

    const differents = [
        { title: "ஆம்", value: "Yes" },
        { title: "இல்லை", value: "No" },
    ];

    const pathams = [
        { title: "0", value: "0" },
        { title: "1", value: "1" },
        { title: "2", value: "2" },
        { title: "3", value: "3" },
        { title: "4", value: "4" },
    ];

    const nmarriage = [
        { title: "விவாகரத்து", value: "Divorce" },
        { title: "விதவை", value: "Widow" },
    ];

    const childrens = [
        { title: "ஆம்", value: "Yes" },
        { title: "இல்லை", value: "No" },
    ];

    const castenahi = [
        { title: "ஆம்", value: "Yes" },
        { title: "இல்லை", value: "No" },
    ];

    const maritals = [
        { title: "முதல் மணம்", value: "Marriage" },
        { title: "மறுமணம்", value: "Remarriage" },
    ];

    const photoshows = [
        { title: "ஆம்", value: "Yes" },
        { title: "இல்லை", value: "No" },
    ];


    const getUserData = async () => {
        // 
        // console.log(userId);
        var test = await getreligionlist();
        // 
        // console.log("fdssfdsfdsf", test);
        const option1 = await test.userValue.map(d => ({
            "title": d.religion,
            "value": d._id
        }));
        setreligion(option1);

        var test = await getdhosamlist();

        // console.log("fdssfdsfdsf", test);
        const dhosamlist = await test.userValue.map(d => ({
            "title": d.dhosamname,
            "value": d._id
        }));
        setdhosam(dhosamlist);
        // var test = await getmaritallist();
        // // 
        // console.log("fdssfdsfdsf", test);
        // const maritals = await test.userValue.map(d => ({
        //     "title": d.maritalname,
        //     "value": d.maritalname
        // }));
        // setmarital(maritals);
        var iddddd = idpass
        var test = await getcastelist(iddddd);
        // 
        // console.log("fdssfdsfdsf", test);
        const castes = await test.userValue.map(d => ({
            "title": d.castename,
            "value": d._id
        }));
        setcaste(castes);

        var test = await getregislist();
        // 
        // console.log("fdssfdsfdsf", test);
        const option = await test.userValue.map(d => ({
            "title": d.regis,
            "value": d._id
        }));
        setoption(option);

        var test = await getcolorlist();
        // 
        // console.log("fdssfdsfdsf", test);
        const colors = await test.userValue.map(d => ({
            "title": d.colorname,
            "value": d._id
        }));
        setcolor(colors);

        var test = await getstarlist();
        // 
        // console.log("fdssfdsfdsf", test);
        const stars = await test.userValue.map(d => ({
            "title": d.starname,
            "value": d._id
        }));
        setstar(stars);

        var test = await getdistrictslist();
        // 
        // console.log("fdssfdsfdsf", test);
        const districts = await test.userValue.map(d => ({
            "title": d.city,
            "value": d._id
        }));
        setdistricts(districts);

        var test = await getzodiaclist();
        // 
        // console.log("fdssfdsfdsf", test);
        const zodiacs = await test.userValue.map(d => ({
            "title": d.zodiacname,
            "value": d._id
        }));
        setzodiac(zodiacs);

        var test = await getdirectionlist();
        // 
        // console.log("fdssfdsfdsf", test);
        const directions = await test.userValue.map(d => ({
            "title": d.directionname,
            "value": d._id
        }));
        setdirection(directions);
        var test = await getsitedata();

        settotalcount(test.totalCount)

        //setUser(test.userValue);
    };
    const add = async () => {
        window.location = "/crm/registeradden";
    };


    useEffect(() => {
        //logout(history)
        getUserData();
        window.scrollTo({ top: 600, left: 0, behavior: 'smooth' });
        // or simply just AOS.init();
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
    }, [idpass]);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    return (
        <div>
            <section className="inner-page-banners">
                <img className="images img-responsive" src={require('../../assests/images/87295banner.png')} />
                <div className="clearfix" />
            </section>

            <div className="container-fluid register-front-end">

                {/* <section style={{ boxShadow: '0px 3px 23px 0px rgb(244, 246, 249)' }}>
                    <div className="container about-us">
                        <div className="contact-banner-heading text-center col-md-offset-4 col-md-3">
                            <div id="triangle_left" />
                            <p>இலவச பதிவு </p>
                            <div id="triangle_right" />
                        </div>
                    </div></section> */}
                <section className="headingzz">
                    <h3 style={{ color: "red" }}> இலவச பதிவு </h3>
                </section>

                <div className="content registerlabel">
                    <div className="reg-top-links">
                        <div className="col-md-6">
                            <h6>ஏற்கனவே மெம்பர் ஆக பதிவு செய்து உள்ளீர்களா?  <Button onClick={handleOpen} style={{ color: "#662e91", fontWeight: "800", fontSize: "14px" }}> Login now </Button> - ஐ கிளிக் செய்யவும்.
                                <br></br>
                                <span>
                                    <Modal
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={style} >
                                            <Button className='timee' style={{ position: "absolute", top: "-24px", right: "-40px", color: "white" }} onClick={handleClose} variant="primary"> <CancelIcon className='rasathi' /> </Button>
                                            <Tabp style={{ marginTop: "10px" }} />

                                        </Box>
                                    </Modal>
                                </span></h6>
                        </div>
                        {/*<div class="col-md-6" style="text-align: right;">*/}
                        {/*<a href="/registers/engregister" style="color: #6a2d91;">Free English Registeration</a>*/}
                        {/* </div>*/}
                        {/* <h4 className="text-center" style={{ color: '#e9018b', fontSize: "17px" }}>  இதுவரை பதிவு செய்தவர்கள் : {totalcount} </h4> */}
                        <h4 className="text-center thunai" style={{ color: '#109110', fontWeight: "600" }}>  உங்கள் வாழ்க்கை துணையை தேர்ந்தெடுக்க ஜாதகத்தை இங்கே பதிவு செய்வீர். </h4>
                    </div>
                    <div className="panel panel-white sowhat">
                        <form className={classes.form}
                            noValidate
                            onSubmit={handleFormSubmit}>
                            <h6 className="form-wizard-title text-semibold">
                                <p>சொந்த விவரங்கள்</p>
                            </h6>

                            <div className="row register-row">
                                <div className="col-md-6" style={{ paddingRight: '10px' }}>

                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>கைபேசி எண்  <span className="reguired">*</span></label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control validate[required,custom[onlyNumberSp],minSize[10],maxSize[10]]"
                                                value={phone_number} id="phone_number" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} onChange={onChange} name="data[Register][phone_number]" />

                                            {validateError.phone_number && (
                                                <span style={{ color: "#950695", fontSize: "13px" }} className={classes.textDanger}>
                                                    {validateError.phone_number}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6" style={{ paddingRight: '10px' }}>

                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>பிறந்த தேதி <span className="reguired">*</span></label>
                                        </div>
                                        <div className="form-group col-md-8 row dob" style={{ paddingRight: 'unset' }}>
                                            <div className="col-md-4" style={{ paddingRight: 'unset' }}>

                                                <InputLabel className={classes.InputLabel} >நாள்</InputLabel>
                                                {/* <Select
                                                    labelId="demo-simple-select-label"
                                                    id="date"
                                                    value={date}
                                                    onChange={(e) => {
                                                        e.preventDefault();

                                                        
                                                        const { id, value } = e.target;
                                                        let formData = { ...formValue, ...{ ["date"]: value } };
                                                        setFormValue(formData);

                                                        
                                                    }}


                                                >
                                                    <MenuItem aria-label="None" value="" />
                                                    {dayoption.map(date => (
                                                        <MenuItem value={date}>
                                                            {date}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {validateError.date && (
                                                    <span className={classes.textDanger}>
                                                        {validateError.date}
                                                    </span>
                                                )} */}


                                                <select
                                                    labelId="demo-simple-select-label"
                                                    id="date"
                                                    value={date}
                                                    onChange={(e) => {
                                                        e.preventDefault();

                                                        // console.log(e.target);
                                                        const { id, value } = e.target;
                                                        let formData = { ...formValue, ...{ ["date"]: value } };
                                                        setFormValue(formData);

                                                    }}
                                                    style={{
                                                        padding: "8px 10px",
                                                        fontSize: "16px",
                                                        margin: "0 5px",
                                                        width: "100%"
                                                    }}
                                                >
                                                    <option value="">Date</option>
                                                    {dayoption.map((date) => (
                                                        <option value={date}>{date}</option>
                                                    ))}
                                                </select>

                                            </div>
                                            <div className="col-md-4" style={{ paddingRight: 'unset' }}>

                                                <InputLabel className={classes.InputLabel} id="age-native-required">மாதம்</InputLabel>
                                                {/* <Select
                                                    labelId="demo-simple-select-label"
                                                    id="month"
                                                    value={month}
                                                    onChange={(e) => {
                                                        e.preventDefault();

                                                      
                                                        const { id, value } = e.target;
                                                        let formData = { ...formValue, ...{ ["month"]: value } };
                                                        setFormValue(formData);

                                                     
                                                    }}

                                                >
                                                    <MenuItem aria-label="None" value="" />
                                                    <MenuItem value={1}>January</MenuItem>
                                                    <MenuItem value={2}>February</MenuItem>
                                                    <MenuItem value={3}>March</MenuItem>
                                                    <MenuItem value={4}>April</MenuItem>
                                                    <MenuItem value={5}>May</MenuItem>
                                                    <MenuItem value={6}>June</MenuItem>
                                                    <MenuItem value={7}>July</MenuItem>
                                                    <MenuItem value={8}>August</MenuItem>
                                                    <MenuItem value={9}>September</MenuItem>
                                                    <MenuItem value={10}>October</MenuItem>
                                                    <MenuItem value={11}>November</MenuItem>
                                                    <MenuItem value={12}>December</MenuItem>
                                                </Select>
                                                {validateError.month && (
                                                    <span className={classes.textDanger}>
                                                        {validateError.month}
                                                    </span>
                                                )} */}

                                                <select
                                                    labelId="demo-simple-select-label"
                                                    // id="loginmonth"
                                                    id="month"
                                                    value={month}
                                                    onChange={(e) => {
                                                        e.preventDefault();


                                                        const { id, value } = e.target;
                                                        let formData = { ...formValue, ...{ ["month"]: value } };
                                                        setFormValue(formData);


                                                    }}
                                                    style={{
                                                        padding: "8px 10px",
                                                        fontSize: "16px",
                                                        margin: "0 5px",
                                                        width: "100%"
                                                    }}
                                                >
                                                    <option value="">Month</option>
                                                    <option value={1}>January</option>
                                                    <option value={2}>February</option>
                                                    <option value={3}>March</option>
                                                    <option value={4}>April</option>
                                                    <option value={5}>May</option>
                                                    <option value={6}>June</option>
                                                    <option value={7}>July</option>
                                                    <option value={8}>August</option>
                                                    <option value={9}>September</option>
                                                    <option value={10}>October</option>
                                                    <option value={11}>November</option>
                                                    <option value={12}>December</option>
                                                </select>

                                            </div>
                                            <div className="col-md-4" style={{ paddingRight: 'unset' }}>

                                                <InputLabel className={classes.InputLabel} id="age-native-required">வருடம்</InputLabel>
                                                {/* <Select
                                                    labelId="demo-simple-select-label"
                                                    id="year"
                                                    value={year}
                                                    onChange={(e) => {
                                                        e.preventDefault();

                                                      
                                                        const { id, value } = e.target;
                                                        let formData = { ...formValue, ...{ ["year"]: value } };
                                                        setFormValue(formData);

                                                       
                                                    }}

                                                >
                                                    <MenuItem aria-label="None" value="" />
                                                    {monthoption.map(year => (
                                                        <MenuItem value={year}>
                                                            {year}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                {validateError.year && (
                                                    <span className={classes.textDanger}>
                                                        {validateError.year}
                                                    </span>
                                                )} */}

                                                <select
                                                    labelId="demo-simple-select-label"
                                                    // id="loginyear"
                                                    id="year"
                                                    value={year}
                                                    onChange={(e) => {
                                                        e.preventDefault();

                                                        const { id, value } = e.target;
                                                        let formData = { ...formValue, ...{ ["year"]: value } };
                                                        setFormValue(formData)
                                                    }}
                                                    style={{
                                                        padding: "8px 10px",
                                                        fontSize: "16px",
                                                        margin: "0 5px",
                                                        width: "100%"
                                                    }}
                                                >
                                                    <option value="">Year</option>
                                                    {monthoption.map(year => (
                                                        <option value={year}>{year}</option>
                                                    ))}
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6" style={{ paddingRight: '10px' }}>

                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>பிறந்த நேரம் </label>
                                        </div>
                                        <div className="col-md-8"><input type="text" className="form-control" onChange={onChange} id="birth_time" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={birth_time} name="data[Register][birth_time]" /></div>
                                        {validateError.birth_time && (
                                            <span className={classes.textDanger}>
                                                {validateError.birth_time}
                                            </span>
                                        )}
                                    </div>

                                </div>


                                <div className="col-md-6">

                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label> மதம் <span className="reguired">*</span></label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={religions}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                value={religions.length ? religion : ""}
                                                onChange={(event, newValue) => {


                                                    // console.log(newValue.value);
                                                    if (newValue.value) {
                                                        let formData = {
                                                            ...formValue,
                                                            ...{ ["religion"]: newValue },
                                                        };

                                                        // console.log(formData, "sdgfgs");
                                                        setidpass(newValue.value)
                                                        setFormValue(formData);
                                                    }
                                                }}
                                                type="text"
                                                name="religion"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="மதம்"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.religion && (
                                                <span className={classes.textDanger}>
                                                    {validateError.religion}
                                                </span>
                                            )}

                                        </div>
                                    </div>

                                </div>


                                <div className="col-md-6">

                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label> ஜாதி  <span className="reguired">*</span></label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={castes}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {
                                                    // 
                                                    // console.log("caste", newValue.value);
                                                    if (newValue.value) {
                                                        let formData = {
                                                            ...formValue,
                                                            ...{ ["castename"]: newValue.value },
                                                        };
                                                        setFormValue(formData);

                                                    }
                                                }}
                                                type="text"
                                                name="castename"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="ஜாதி"
                                                        label="Select"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.castename && (
                                                <span className={classes.textDanger}>
                                                    {validateError.castename}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                </div>


                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>உட்பிரிவு </label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" onChange={onChange} id="subcaste" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={subcaste} name="data[Register][subcaste]" onkeypress="javascript:convertThis(event);" />
                                            {validateError.subcaste && (
                                                <span className={classes.textDanger}>
                                                    {validateError.subcaste}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label> பெயர் <span className="reguired">*</span></label>
                                        </div>
                                        <div className="col-md-8"><input onkeypress="javascript:convertThis(event);" onChange={onChange} type="text" id="mname" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={mname} className="form-control validate[required]" name="data[Register][mname]" /></div>
                                        {validateError.mname && (
                                            <span style={{ color: "#950695", fontSize: "13px" }} className={classes.textDanger}>
                                                {validateError.mname}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label> பாலினம்  <span className="reguired">*</span></label>
                                        </div>
                                        <div className="col-md-8">

                                            <Autocomplete
                                                options={sexch}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {

                                                    // console.log(newValue.value);

                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["sex"]: newValue.value },
                                                    };
                                                    setFormValue(formData);

                                                }}
                                                type="text"
                                                name="sex"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="பாலினம்"
                                                        label="Select"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.sex && (
                                                <span className={classes.textDanger}>
                                                    {validateError.sex}
                                                </span>
                                            )}
                                        </div>
                                        {/* <div className="col-md-8">
                                            <select className="form-control  validate[required]" value={sex} id="sex" name="data[Register][sex]">
                                                <option value="Male">Male&nbsp;&nbsp;</option>
                                                <option value="Female">&nbsp;Female</option>
                                            </select>
                                        </div> */}
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>உயரம் <span className="reguired">*</span></label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={heights}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {

                                                    // console.log(newValue.value);
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["height"]: newValue.value },
                                                    };
                                                    setFormValue(formData);
                                                }}
                                                type="text"
                                                name="height"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="உயரம் "
                                                        label="Select"
                                                        variant="outlined"
                                                        className="vin"
                                                    />
                                                )}
                                            />
                                            {validateError.height && (
                                                <span className={classes.textDanger}>
                                                    {validateError.height}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>நிறம்</label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={colors}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {
                                                    // 
                                                    // console.log(newValue.value);
                                                    if (newValue.value) {
                                                        let formData = {
                                                            ...formValue,
                                                            ...{ ["colorname"]: newValue.value },
                                                        };
                                                        setFormValue(formData);
                                                    }
                                                }}
                                                type="text"
                                                name="colorname"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="நிறம்"
                                                        label="Select"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.colorname && (
                                                <span className={classes.textDanger}>
                                                    {validateError.colorname}
                                                </span>
                                            )}
                                        </div>

                                    </div>
                                </div>



                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>கல்வி தகுதி   <span className="reguired">*</span></label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control validate[required]" onChange={onChange} id="educ" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={educ} name="data[Register][educ]" />
                                            {validateError.educ && (
                                                <span className={classes.textDanger}>
                                                    {validateError.educ}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>வேலை, தொழில்   <span className="reguired">*</span></label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control validate[required]" onChange={onChange} id="occup" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={occup} name="data[Register][occup]" onkeypress="javascript:convertThis(event);" />
                                            {validateError.occup && (
                                                <span className={classes.textDanger}>
                                                    {validateError.occup}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>தந்தையின் பெயர் <label>
                                            </label></label></div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" onChange={onChange} id="father_name" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={father_name} name="data[Register][father_name]" onkeypress="javascript:convertThis(event);" />
                                            {validateError.father_name && (
                                                <span className={classes.textDanger}>
                                                    {validateError.father_name}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>தந்தையின் வேலை </label>
                                        </div>
                                        <div className="col-md-8">
                                            <input className="form-control" name="data[Register][dad_work]" onChange={onChange} id="dad_work" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={dad_work} onkeypress="javascript:convertThis(event);" defaultValue={""} />
                                            {validateError.dad_work && (
                                                <span className={classes.textDanger}>
                                                    {validateError.dad_work}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>தாய் பெயர் <label>
                                            </label></label></div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" onChange={onChange} id="mother_name" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={mother_name} name="data[Register][mother_name]" onkeypress="javascript:convertThis(event);" />
                                            {validateError.mother_name && (
                                                <span className={classes.textDanger}>
                                                    {validateError.mother_name}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>தாயின் வேலை</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input className="form-control" name="data[Register][mom_work]" onChange={onChange} id="mom_work" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={mom_work} onkeypress="javascript:convertThis(event);" defaultValue={""} />
                                            {validateError.mom_work && (
                                                <span className={classes.textDanger}>
                                                    {validateError.mom_work}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>சகோதரர்கள் </label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={brothers}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {

                                                    // console.log(newValue.value);
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["brother"]: newValue.value },
                                                    };
                                                    setFormValue(formData);
                                                }}
                                                type="text"
                                                name="brother"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="சகோதரர்கள்"
                                                        label="Select"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.brother && (
                                                <span className={classes.textDanger}>
                                                    {validateError.brother}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>திருமணமான சகோதரர்கள்  </label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={mbrothers}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {

                                                    // console.log(newValue.value);
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["m_brother"]: newValue.value },
                                                    };
                                                    setFormValue(formData);
                                                }}
                                                type="text"
                                                name="m_brother"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="திருமணமான சகோதரர்கள்"
                                                        label="Select"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.m_brother && (
                                                <span className={classes.textDanger}>
                                                    {validateError.m_brother}
                                                </span>
                                            )}

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>சகோதரிகள் </label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={sisters}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {

                                                    // console.log(newValue.value);
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["sister"]: newValue.value },
                                                    };
                                                    setFormValue(formData);
                                                }}
                                                type="text"
                                                name="sister"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="சகோதரிகள்"
                                                        label="Select"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.sister && (
                                                <span className={classes.textDanger}>
                                                    {validateError.sister}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>திருமணமான சகோதரிகள் </label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={msisters}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {

                                                    // console.log(newValue.value);
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["m_sister"]: newValue.value },
                                                    };
                                                    setFormValue(formData);
                                                }}
                                                type="text"
                                                name="m_sister"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="திருமணமான சகோதரிகள்"
                                                        label="Select"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.m_sister && (
                                                <span className={classes.textDanger}>
                                                    {validateError.m_sister}
                                                </span>
                                            )}

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>மாற்றுத் திறனாளி<spabel>
                                            </spabel></label></div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={differents}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                value={different}
                                                onChange={(event, newValue) => {
                                                    if (newValue.value == "No") {

                                                        setdifferent("none");

                                                    } else {
                                                        setdifferent("block");
                                                    }

                                                    //   console.log(newValue.value);
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["different"]: newValue },
                                                    };
                                                    setFormValue(formData);
                                                }}
                                                type="text"
                                                name="different"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="மாற்றுத்திறனாளி"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.different && (
                                                <span className={classes.textDanger}>
                                                    {validateError.different}
                                                </span>
                                            )}

                                        </div>
                                    </div>

                                    <div className="form-group row handicap" style={{ display: handyreason }}>
                                        <div className="col-md-4">
                                            <label>குறிப்பிடுக:</label>
                                        </div>
                                        <div className="col-md-8">
                                            <textarea className="form-control" onChange={onChange} id="handireason" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={handireason} name="data[Register][handireason]" onkeypress="javascript:convertThis(event);" defaultValue={""} />
                                            {validateError.handireason && (
                                                <span className={classes.textDanger}>
                                                    {validateError.handireason}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>திருமண நிலை <span className="reguired">*</span></label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={maritals}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                value={maritalname}
                                                onChange={(event, newValue) => {
                                                    // 
                                                    // console.log(newValue.value,"marital");
                                                    if (newValue.value == "Marriage") {

                                                        setUnmarried("none");

                                                    } else {
                                                        setUnmarried("block");
                                                    }

                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["maritalname"]: newValue },
                                                    };
                                                    setFormValue(formData);

                                                }}
                                                type="text"
                                                name="maritalname"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="திருமண நிலை"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.maritalname && (
                                                <span className={classes.textDanger}>
                                                    {validateError.maritalname}
                                                </span>
                                            )}
                                        </div>

                                    </div>

                                    <div className="form-group row children" style={{ display: remarriage }}>
                                        <div className="col-md-4">
                                            <label> மறுமணம் காரணம்</label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={nmarriage}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {

                                                    // console.log(newValue.value);
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["n_marriage"]: newValue.value },
                                                    };
                                                    setFormValue(formData);
                                                }}
                                                type="text"
                                                name="nmarriage"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="மறுமணம் காரணம்"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />

                                            {validateError.n_marriage && (
                                                <span className={classes.textDanger}>
                                                    {validateError.n_marriage}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-group row children" style={{ display: remarriage }}>
                                        <div className="col-md-4">
                                            <label>குழந்தைகள்</label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={childrens}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {

                                                    if (newValue.value == "No") {

                                                        setchild("none");

                                                    } else {
                                                        setchild("block");
                                                    }

                                                    //   console.log(newValue.value);
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["children"]: newValue.value },
                                                    };
                                                    setFormValue(formData);
                                                }}
                                                type="text"
                                                name="children"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="குழந்தைகள்"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />

                                            {validateError.children && (
                                                <span className={classes.textDanger}>
                                                    {validateError.children}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className="form-group row children" style={{ display: childdetail }}>
                                        <div className="col-md-4">
                                            <label>குழந்தைகள் விவரங்கள்
                                            </label></div>
                                        <div className="col-md-8">
                                            <textarea className="form-control" onChange={onChange} id="child_detail" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={child_detail} name="data[Register][child_detail]" onkeypress="javascript:convertThis(event);" defaultValue={""} />
                                            {validateError.child_detail && (
                                                <span className={classes.textDanger}>
                                                    {validateError.child_detail}
                                                </span>
                                            )}
                                        </div>
                                    </div>


                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>சம்பள வருமானம் </label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" onChange={onChange} id="income" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={income} name="data[Register][income]" />
                                            {validateError.income && (
                                                <span className={classes.textDanger}>
                                                    {validateError.income}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>பணிபுரியுமிடம்</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="data[Register][w_place]" onChange={onChange} id="w_place" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={w_place} onkeypress="javascript:convertThis(event);" />
                                            {validateError.w_place && (
                                                <span className={classes.textDanger}>
                                                    {validateError.w_place}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>பூர்வீகம்</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="data[Register][puriveaam]" onChange={onChange}
                                                value={puriveaam} id="puriveaam" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} onkeypress="javascript:convertThis(event);" />
                                            {validateError.puriveaam && (
                                                <span className={classes.textDanger}>
                                                    {validateError.puriveaam}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>இருப்பிடம் </label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="data[Register][livesity]" onChange={onChange}
                                                value={livesity} id="livesity" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} onkeypress="javascript:convertThis(event);" />
                                            {validateError.livesity && (
                                                <span className={classes.textDanger}>
                                                    {validateError.livesity}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>சீர்வரிசை</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="data[Register][dever]" onChange={onChange}
                                                value={dever} id="dever" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} onkeypress="javascript:convertThis(event);" />
                                            {validateError.dever && (
                                                <span className={classes.textDanger}>
                                                    {validateError.dever}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                 <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>சொத்துக்கள்</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="data[Register][peroparty]" onChange={onChange}
                                                value={peroparty} id="peroparty" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} onkeypress="javascript:convertThis(event);" />
                                            {validateError.peroparty && (
                                                <span className={classes.textDanger}>
                                                    {validateError.peroparty}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>எதிர்பார்ப்பு</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="data[Register][pob]" onChange={onChange}
                                                value={pob} id="pob" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} onkeypress="javascript:convertThis(event);" />
                                            {validateError.pob && (
                                                <span className={classes.textDanger}>
                                                    {validateError.pob}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>குலதெய்வம்<spabel>
                                            </spabel></label></div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="data[Register][bestgod]" onChange={onChange} id="bestgod" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={bestgod} onkeypress="javascript:convertThis(event);" />
                                            {validateError.bestgod && (
                                                <span className={classes.textDanger}>
                                                    {validateError.bestgod}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>நட்சத்திரம் <span className="reguired">*</span></label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={stars}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {
                                                    // 
                                                    // console.log(newValue.value);
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["starname"]: newValue.value },
                                                    };
                                                    setFormValue(formData);
                                                }}
                                                type="text"
                                                name="starname"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="நட்சத்திரம்"
                                                        label="Select"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.starname && (
                                                <span className={classes.textDanger}>
                                                    {validateError.starname}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>பாதம்</label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={pathams}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {

                                                    // console.log(newValue.value);
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["patham"]: newValue.value },
                                                    };
                                                    setFormValue(formData);
                                                }}
                                                type="text"
                                                name="patham"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="பாதம்"
                                                        label="Select"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.patham && (
                                                <span className={classes.textDanger}>
                                                    {validateError.patham}
                                                </span>
                                            )}

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label> ராசி <span className="reguired">*</span></label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={zodiacs}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {

                                                    // console.log(newValue.value);
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["zodiacname"]: newValue.value },
                                                    };
                                                    setFormValue(formData);
                                                }}
                                                type="text"
                                                name="zodiacname"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="ராசி"
                                                        label="Select"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.zodiacname && (
                                                <span className={classes.textDanger}>
                                                    {validateError.zodiacname}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>தோஷம்</label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={dhosams}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {

                                                    // console.log(newValue.value);
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["dhosamname"]: newValue.value },
                                                    };
                                                    setFormValue(formData);
                                                }}
                                                type="text"
                                                name="dhosamname"
                                                renderInput={(params) => (
                                                    <TextField
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        {...params}
                                                        // label="தோஷம்"
                                                        label="Select"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />


                                            {validateError.dhosamname && (
                                                <span className={classes.textDanger}>
                                                    {validateError.dhosamname}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>திசை இருப்பு </label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={directions}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {

                                                    // console.log(newValue.value);
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["directionname"]: newValue.value },
                                                    };
                                                    setFormValue(formData);
                                                }}
                                                type="text"
                                                name="directionname"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="திசைகள்"
                                                        label="Select"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.directionname && (
                                                <span className={classes.textDanger}>
                                                    {validateError.directionname}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>லக்னம்</label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={zodiacs}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {

                                                    // console.log(newValue.value);
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["gothra"]: newValue.value },
                                                    };
                                                    setFormValue(formData);
                                                }}
                                                type="text"
                                                name="gothra"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="லக்னம்" 
                                                        label="Select"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.gothra && (
                                                <span className={classes.textDanger}>
                                                    {validateError.gothra}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <InputLabel className={classes.InputLabel} >வருடம்</InputLabel>
                                            {/* <Select
                                                labelId="demo-simple-select-label"
                                                id="s_year"
                                                value={s_year}
                                                onChange={(e) => {
                                                    e.preventDefault();

                                                   
                                                    const { id, value } = e.target;
                                                    let formData = { ...formValue, ...{ ["s_year"]: value } };
                                                    setFormValue(formData);

                                                  
                                                }}

                                            >
                                                <MenuItem aria-label="None" value="" />
                                                {monthoption.map(s_year => (
                                                    <MenuItem value={s_year}>
                                                        {s_year}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {validateError.s_year && (
                                                <span className={classes.textDanger}>
                                                    {validateError.s_year}
                                                </span>
                                            )} */}

                                            <select
                                                labelId="demo-simple-select-label"
                                                // id="loginyear"
                                                id="s_year"
                                                value={s_year}
                                                onChange={(e) => {
                                                    e.preventDefault();


                                                    const { id, value } = e.target;
                                                    let formData = { ...formValue, ...{ ["s_year"]: value } };
                                                    setFormValue(formData);


                                                }}

                                                style={{
                                                    padding: "8px 10px",
                                                    fontSize: "16px",
                                                    margin: "0 5px",
                                                    width: "100%"
                                                }}
                                            >
                                                <option value="">Year</option>
                                                {monthoption.map(year => (
                                                    <option value={year}>{year}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <InputLabel className={classes.InputLabel} id="age-native-required">மாதம்</InputLabel>
                                            {/* <Select
                                                labelId="demo-simple-select-label"
                                                id="s_month"
                                                value={s_month}
                                                onChange={(e) => {
                                                    e.preventDefault();

                                                   
                                                    const { id, value } = e.target;
                                                    let formData = { ...formValue, ...{ ["s_month"]: value } };
                                                    setFormValue(formData);
                                                }}

                                            >
                                                <MenuItem aria-label="None" value="" />
                                                <MenuItem value={1}>January</MenuItem>
                                                <MenuItem value={2}>February</MenuItem>
                                                <MenuItem value={3}>March</MenuItem>
                                                <MenuItem value={4}>April</MenuItem>
                                                <MenuItem value={5}>May</MenuItem>
                                                <MenuItem value={6}>June</MenuItem>
                                                <MenuItem value={7}>July</MenuItem>
                                                <MenuItem value={8}>August</MenuItem>
                                                <MenuItem value={9}>September</MenuItem>
                                                <MenuItem value={10}>October</MenuItem>
                                                <MenuItem value={11}>November</MenuItem>
                                                <MenuItem value={12}>December</MenuItem>
                                            </Select>
                                            {validateError.s_month && (
                                                <span className={classes.textDanger}>
                                                    {validateError.s_month}
                                                </span>
                                            )} */}
                                            <select
                                                labelId="demo-simple-select-label"
                                                // id="loginmonth"
                                                id="s_month"
                                                value={s_month}
                                                onChange={(e) => {
                                                    e.preventDefault();


                                                    const { id, value } = e.target;
                                                    let formData = { ...formValue, ...{ ["s_month"]: value } };
                                                    setFormValue(formData);
                                                }}
                                                style={{
                                                    padding: "8px 10px",
                                                    fontSize: "16px",
                                                    margin: "0 5px",
                                                    width: "100%"
                                                }}
                                            >
                                                <option value="">Month</option>
                                                <option value={1}>January</option>
                                                <option value={2}>February</option>
                                                <option value={3}>March</option>
                                                <option value={4}>April</option>
                                                <option value={5}>May</option>
                                                <option value={6}>June</option>
                                                <option value={7}>July</option>
                                                <option value={8}>August</option>
                                                <option value={9}>September</option>
                                                <option value={10}>October</option>
                                                <option value={11}>November</option>
                                                <option value={12}>December</option>
                                            </select>
                                        </div>
                                        <div className="col-md-4">
                                            <InputLabel className={classes.InputLabel} id="age-native-required">நாள்</InputLabel>
                                            {/* <Select
                                                labelId="demo-simple-select-label"
                                                id="s_day"
                                                value={s_day}
                                                onChange={(e) => {
                                                    e.preventDefault();

                                                    
                                                    const { id, value } = e.target;
                                                    let formData = { ...formValue, ...{ ["s_day"]: value } };
                                                    setFormValue(formData);

                                                }}

                                            >
                                                <MenuItem aria-label="None" value="" />
                                                {dayoption.map(s_day => (
                                                    <MenuItem value={s_day}>
                                                        {s_day}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {validateError.s_day && (
                                                <span className={classes.textDanger}>
                                                    {validateError.s_day}
                                                </span>
                                            )} */}


                                            <select
                                                labelId="demo-simple-select-label"
                                                // id="logindate"
                                                id="s_day"
                                                value={s_day}
                                                onChange={(e) => {
                                                    e.preventDefault();

                                                    // console.log(e.target);
                                                    const { id, value } = e.target;
                                                    let formData = { ...formValue, ...{ ["s_day"]: value } };
                                                    setFormValue(formData);

                                                }}
                                                style={{
                                                    padding: "8px 10px",
                                                    fontSize: "16px",
                                                    margin: "0 5px",
                                                    width: "100%"
                                                }}
                                            >
                                                <option value="">Date</option>
                                                {dayoption.map((date) => (
                                                    <option value={date}>{date}</option>
                                                ))}
                                            </select>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row alignzzz" style={{ alignItems: "center" }}>
                                        <div className="col-md-6">
                                            <label> ஜாதி தடை இல்லை </label>
                                            <FormControlLabel
                                                style={{ color: "black", fontSize: "20px" }}
                                                className="licence"
                                                control={<Checkbox onChange={(event) => setcastenone(!castenone)} name="castenone" />}

                                            />
                                        </div>
                                    </div>

                                </div>


                            </div>


                            {/* <div className="row register-row">

                                <div className="col-md-6" style={{ paddingRight: '30px' }}>




                                </div>

                                <div className="col-md-6" style={{ paddingLeft: '30px' }}>


                                </div>
                            </div> */}



                            <h6 className="form-wizard-title text-semibold">
                                <p>ஜாதக விவரங்கள்</p>
                            </h6>
                            <div className="row register-row" style={{ paddingBottom: '0px' }}>

                                <div className="col-md-4" style={{ paddingLeft: '30px' }}>



                                </div>
                            </div>
                            <div className style={{ marginTop: '30px' }}>
                                <img src={require('../../assests/images/chat_new.png')} className="img-responsive" style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                                <div className="form-group table-responsive">
                                    <table className="rassi-table" style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '50px' }}>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={r1}
                                                        id="r1"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(r1)}</p>
                                                </td>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={r2}
                                                        id="r2"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(r2)}</p>
                                                </td>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={r3}
                                                        id="r3"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(r3)}</p>
                                                </td>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={r4}
                                                        id="r4"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(r4)}</p>
                                                </td>
                                                <div></div>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={a1}
                                                        id="a1"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(a1)}</p>
                                                </td>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={a2}
                                                        id="a2"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(a2)}</p>
                                                </td>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={a3}
                                                        id="a3"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(a3)}</p>
                                                </td>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={a4}
                                                        id="a4"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(a4)}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={r5}
                                                        id="r5"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(r5)}</p>
                                                </td>
                                                <td colspan="2" rowspan="2" width="124" align="center">
                                                    <img src={require('../../assests/images/raaasi.png')} width={112} height={45} />
                                                </td>
                                                <td><TextareaAutosize
                                                    onChange={onChange}
                                                    value={r6}
                                                    id="r6"
                                                    aria-label="minimum height"
                                                    minRows={3}
                                                    Cols={8}
                                                    formControlProps={{
                                                        fullWidth: true,
                                                    }} />
                                                    <p class="jagtext">{changenumbertojathagam(r6)}</p>
                                                </td>
                                                <div></div>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={a5}
                                                        id="a5"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(a5)}</p>
                                                </td>
                                                <td rowspan="2" colspan="2" width="133" align="center">
                                                    <img src={require('../../assests/images/amsam.png')} width={112} height={45} />
                                                </td>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={a6}
                                                        id="a6"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(a6)}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={r7}
                                                        id="r7"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(r7)}</p>
                                                </td>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={r8}
                                                        id="r8"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(r8)}</p>
                                                </td>
                                                <div></div>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={a7}
                                                        id="a7"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(a7)}</p>
                                                </td>
                                                <td width="57" height="63" align="center">
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={a8}
                                                        id="a8"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(a8)}</p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={r9}
                                                        id="r9"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(r9)}</p>
                                                </td>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={r10}
                                                        id="r10"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(r10)}</p>
                                                </td>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={r11}
                                                        id="r11"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(r11)}</p>
                                                </td>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={r12}
                                                        id="r12"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(r12)}</p>
                                                </td>
                                                <div></div>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={a9}
                                                        id="a9"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(a9)}</p>
                                                </td>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={a10}
                                                        id="a10"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(a10)}</p>
                                                </td>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={a11}
                                                        id="a11"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(a11)}</p>
                                                </td>
                                                <td>
                                                    <TextareaAutosize
                                                        onChange={onChange}
                                                        value={a12}
                                                        id="a12"
                                                        aria-label="minimum height"
                                                        minRows={3}
                                                        Cols={8}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }} />
                                                    <p class="jagtext">{changenumbertojathagam(a12)}</p>
                                                </td>
                                            </tr>
                                        </tbody>

                                    </table>

                                </div>
                                <p className="pathivu">அம்சத்தை பதிவு செய்ய கர்ஷரை வலது பக்கம் நகர்த்தவும். </p>
                            </div>



                            <h6 className="form-wizard-title text-semibold c-detail">
                                <p>Contact Details</p>
                            </h6>


                            <div className="row register-row">

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>பதிவுசெய்பவர் <span className="reguired">*</span></label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={options}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {

                                                    // console.log(newValue.value);
                                                    let formData = {
                                                        ...formValue,
                                                        ...{ ["regis"]: newValue.value },
                                                    };
                                                    setFormValue(formData);
                                                }}
                                                type="text"
                                                name="regis"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="பதிவுசெய்பவர்"
                                                        label="Select"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.regis && (
                                                <span className={classes.textDanger}>
                                                    {validateError.regis}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>மாவட்டம்<span className="reguired">*</span></label>
                                        </div>
                                        <div className="col-md-8">
                                            <Autocomplete
                                                options={districts}
                                                // noOptionsText="Enter to create a new option"
                                                getOptionLabel={(option) => option.title}
                                                onChange={(event, newValue) => {


                                                    // console.log(newValue.value);
                                                    if (newValue.value) {
                                                        let formData = {
                                                            ...formValue,
                                                            ...{ ["city"]: newValue.value },
                                                        };

                                                        // console.log(formData, "sdgfgs");
                                                        setFormValue(formData);
                                                    }
                                                }}
                                                type="text"
                                                name="city"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        // label="மாவட்டம்"
                                                        label="Select"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                            {validateError.city && (
                                                <span className={classes.textDanger}>
                                                    {validateError.city}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>முகவரி<span className="reguired">*</span></label>
                                        </div>
                                        <div className="col-md-8">
                                            <TextareaAutosize labelText="Address"
                                                onChange={onChange}
                                                style={{ fontSize: "15px", width:"100%" }}
                                                id="address"
                                                minRows={6}
                                                Cols={40}
                                                value={address}
                                                formControlProps={{
                                                    fullWidth: true,

                                                }} />
                                            {validateError.address && (
                                                <span className={classes.textDanger}>
                                                    {validateError.address}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row" style={{ marginTop: '35px' }}>
                                        <div className="col-md-4">
                                            <label>கூடுதல் தொலைபேசி எண்</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="data[Register][mobile_number]" onChange={onChange} id="mobile_number" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={mobile_number} onkeypress="javascript:convertThis(event);" />
                                        </div>
                                    </div>

                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>இமெயில் முகவரி </label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" name="data[Register][email_id]" onChange={onChange} id="email_id" style={{ padding: "22px", fontSize: "16px", color: "#000000d4", fontWeight: "700" }} value={email_id} onkeypress="javascript:convertThis(event);" />
                                            {validateError.email_id && (
                                                <span className={classes.textDanger}>
                                                    {validateError.email_id}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>புகைப்படம்</label>
                                        </div>
                                        <div className="col-md-8">
                                            <CustomInput

                                                onChange={handleFile}
                                                id="photopath"
                                                type="file"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                            />

                                            {validateError.photopath && (
                                                <span className={classes.textDanger}>
                                                    {validateError.photopath}
                                                </span>
                                            )}
                                            <p style={{ marginTop: '10px', whiteSpace: 'nowrap', fontSize: "17px" }}><small>Recommended Size: 360*520</small></p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label>ஜாதக புகைப்படம்</label>
                                        </div>
                                        <div className="col-md-8">
                                            <CustomInput

                                                onChange={handleFile}
                                                id="jathgampic"
                                                type="file"
                                                formControlProps={{
                                                    fullWidth: true,
                                                }}
                                            />

                                            {validateError.jathgampic && (
                                                <span className={classes.textDanger}>
                                                    {validateError.jathgampic}
                                                </span>
                                            )}
                                            <p style={{ marginTop: '10px', whiteSpace: 'nowrap', fontSize: "17px" }}><small>Recommended Size: 360*520</small></p>
                                        </div>
                                    </div>
                                </div>

                             
                            </div>

                            {/* <div className="row register-row">
                                <div className="col-md-6" style={{ paddingRight: '30px' }}>
                                    
                                </div>


                                <div className="col-md-6" style={{ paddingLeft: '30px' }}>

                                
                                </div>
                            </div> */}



                            <div className="form-group" style={{ paddingLeft: '20px', fontSize: '19px' }}>
                                <input type="checkbox" name="data[Register][accept]" defaultValue="ON" defaultChecked style={{ marginRight: '7px' }} />I Accept the <a href="/terms" target="_blank">term and Conditions</a>
                            </div>
                            <div style={{ textAlign: "left", marginLeft: "18px" }}>
                                <Robot />
                            </div>
                            <div className>
                                <div className="g-recaptcha" data-sitekey="6LcC4aQUAAAAAKnqHg8grPN1AaevX5HXp1SDNlr_" />
                            </div>
                            <div style={{ textAlign: 'center', margin: "25px 25px" }}>
                                <input className="btn btn-info reg-submit" defaultValue="Submit" type="submit" />
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >












    )
}

