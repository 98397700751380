import React, { useState, useEffect } from "react";
import {  getsitedata, addenquiry} from "./../action/user";
import Robot from '../Robot/robot'
import AOS from 'aos';

function Contact() {

 const initialFormValue = {
        name: "",
        email: "",
        subject:"",
        message: "",
      };
    
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});

  const [sites, setSites]=useState([]);
  const [enquirys, setEnquiry]=useState([]);


  const getPieData = async () => {
  var test =  await getsitedata();
  setSites(test.userValue[0]);
  
//   console.log(test, "bird");


 

 
    }
  
  useEffect(() => {
    //logout(history)
    getPieData();
    window.scrollTo({ top: 550, left: 0, behavior: 'smooth' });
    
                  // or simply just AOS.init();
                  AOS.init({
                    // initialise with other settings
                    duration: 2000
                });
  }, []);

 
  const handleFile = (event) => {
    event.preventDefault();
    
    // console.log(event.target.files[0]);
    const { id, files } = event.target;
    //settmpupimagefront(URL.createObjectURL(event.target.files[0]));
    let formData = { ...formValue, ...{ [id]: files[0] } };
    setFormValue(formData);
    //setValidateError(formData)
  };


  // function

  const onChange = (e) => {
    e.preventDefault();
    //  var numchar= Number;
    //  var lang = convertThis(e,numchar);
    
    // console.log(e.target);
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    
    // console.log(formValue);
    //setValidateError(formData)
  };


  const {
    name,
    email,
    subject,
    message,
  } = formValue;


  const handleFormSubmit = async (e) => {
    //
    // console.log("saran");
    e.preventDefault();
    
    // console.log(formValue);
    let reqData = {
       name,
       email,
       subject,
       message,
    };
    
    // console.log("req", reqData);
    let { error } = await addenquiry(reqData);
    // 
    // console.log("error", error);
    if (error) {
        // toast.success("Enquiry added", toasterOption);
        // history.push("/");
        window.location = "/";
    } else {
        setValidateError(error);
    }
}


    return (
        <div>
        <section className="inner-page-banners">
            <img className="images img-responsive" src= { require('../../assests/images/87295banner.png') }/>
            <div className="clearfix" />
        </section>

        {/* <section style={{ boxShadow: '0px 3px 23px 0px rgb(244, 246, 249)' }}>
            <div className="container contact-us">
                <div className="contact-banner-heading text-center col-md-offset-4 col-md-5">
                    <div id="triangle_left" />
                    <p>தொடர்பு கொள்ள</p>
                    <div id="triangle_right" />
                </div>
            </div></section> */}

            <section className="headingzz">
                <h3> தொடர்பு கொள்ள </h3>
            </section>

        {/*-728x90-*/}
        <div className="aboutdetails">
            <div className="text-center aboutdetail">
                <h2>மல்லிகை திருமண தகவல் மையம்</h2>
            </div>
        </div>
        <div className="officeaddress" data-aos="fade-up">
            <div className="container">
                <div className="address">

         
         <div class="feat bg-gray ">
    <div class="container">
      <div class="row">      
        {/* <div class="section-head col-sm-12">
          <h4><span>Why Choose</span> Us?</h4>
          <p>When you choose us, you'll feel the benefit of 10 years' experience of Web Development. Because we know the digital world and we know that how to handle it. With working knowledge of online, SEO and social media.</p>
        </div> */}
        <div class="col-lg-4 col-sm-6">
          <div class="sa_item"> <span class="icon feature_box_col_one"><i class="fa-solid fa-address-book"></i></span>
            <h6>Address</h6>
                                            <p>மல்லிகை திருமண தகவல் மையம்<br></br>
                                                No.22,மெங்கில்ஸ் ரோடு,
                                                நாகல் நகர்,திண்டுக்கல் -624003</p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="sa_item"> <span class="icon feature_box_col_one"><i class="fa-solid fa-phone"></i></span>
            <h6>Phone Number</h6>
                                            <p class="sa_cont" style={{ fontSize: "17px" }}>
                                                +91 95664 66587<br></br>
                                                +91 82487 55158<br></br>
                                                {/* +91 98425 79509 */}
                                            </p>
          </div>
        </div>
        <div class="col-lg-4 col-sm-6">
          <div class="sa_item"> <span class="icon feature_box_col_two"><i class="fa-solid fa-at"></i></span>
            <h6>Email</h6>
            <p class="sa_cont" style={{ fontSize: "16px" }}>support@srimalligaimatrimony.in</p>
          </div>
        </div>

      </div>
    </div>
  </div>

                    {/* <div className="row">
                        <div className="col-md-offset-1 col-md-5 col-sm-6 col-xs-6">
                            <div className="contactaddress">
                                <h2 className="text-center" style={{ fontWeight: '800' }}>Main Office</h2>
                                <div className="media">
                                <div className="media-left col-2" style={{textAlign:"right"}}>
                                    <img className="logo" style={{ height:"24px"}} src={require('../../assests/images/location1.png') } /> 
                                    </div>
                                    <div className="media-body col-10" style={{ fontSize: '17px',textAlign:"left" }}>
                                        <p>{sites.address}</p>
                                    </div>
                                </div>
                                <div className="media">
                                    <div className="media-left">
                                        <i className="fa fa-volume-control-phone" aria-hidden="true" />  </div>
                                    <div className="media-body" style={{textAlign:"left",paddingLeft:"40px"}} >
                                        <p><img className="logo" style={{ height:"20px"}} src={ require('../../assests/images/phonecall.png') } />+91 {sites.phoneNo}</p>
                                        <p><img className="logo" style={{ height:"20px"}} src={ require('../../assests/images/phonecall.png') } /> 82202 04747</p>
                                        <p> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5 col-sm-6 col-xs-6">
                            <div className="contactaddress">
                                <h2 className="text-center" style={{ fontWeight: '800' }}>Branch Office</h2>
                                <div className="media row">
                                    <div className="media-left col-2" style={{textAlign:"right"}}>
                                    <img className="logo" style={{ height:"24px"}} src={ require('../../assests/images/location1.png') } />
                                    </div>
                                    <div className="media-body col-10">
                                        <pre style={{textAlign:"left"}}> எண் 21/23, சாமிகண்ணு தெரு,{"\n"}பெத்தானியாபுரம்,{"\n"}குரு தியேட்டர் எதிரில்,{"\n"}ஆரப்பாளையம், {"\n"}மதுரை - 625016.</pre><p />
                                    </div>
                                </div>
                                <div className="media">
                                    <div className="media-left">
                                        <i className="fa fa-volume-control-phone" aria-hidden="true" />  </div>
                                    <div className="media-body" style={{textAlign:"left",paddingLeft:"40px"}}>
                                        <p><img className="logo" style={{ height:"20px"}} src={ require('../../assests/images/phonecall.png') } /> 82202 04747</p>
                                        <p><img className="logo" style={{ height:"20px"}} src={ require('../../assests/images/phonecall.png') } /> 0452 4954442</p>
                                        <p><img className="logo" style={{ height:"20px"}} src={ require('../../assests/images/phonecall.png') } /> 0452 4961222</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <p className="working text-center"> வேலை நேரம்  :  திங்கட்கிழமை - ஞாயிற்றுக்கிழமை 9 am to 7 pm </p>
                </div>
                <div className="map">
             
            {/* <img className="map-style" src= { require('../../assests/images/map.png') }/> */}
             
             
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15699.368011019664!2d77.9793541!3d10.3545154!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00ab87baa5fecd%3A0x13ffb6e1b50e9244!2sMallikai%20thirumana%20thagaval%20maiyam!5e0!3m2!1sen!2sin!4v1712828067729!5m2!1sen!2sin" width="600" height="450" rameBorder={0} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.9499858081194!2d78.12821947502982!3d9.938119574096465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00c5ebaaaaaaab%3A0xbf6e84a5b21cf6f8!2z4K6k4K6_4K6w4K-B4K6u4K6V4K6z4K-NIOCuruCvh-Cun-CvjeCusOCuv-CuruCvi-CuqeCuvw!5e0!3m2!1sen!2sin!4v1697281864246!5m2!1sen!2sin" width={600} height={450} frameBorder={0} style={{ border: 0 }} allowFullScreen /> */}
                 {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.9499858081194!2d78.12821947502982!3d9.938119574096465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b00c5ebaaaaaaab%3A0xbf6e84a5b21cf6f8!2z4K6k4K6_4K6w4K-B4K6u4K6V4K6z4K-NIOCuruCvh-Cun-CvjeCusOCuv-CuruCvi-CuqeCuvw!5e0!3m2!1sen!2sin!4v1697281864246!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                </div>
            </div>
        </div>

        {/*contact enquiry*/}
        <div className="enquiryform" data-aos="fade-zoom-in">
            <div className="enquiry">
                <h3 className="text-center" style={{ fontSize:"25px" }}>GET ENQUIRY</h3>
            </div>
            <div className="row">
                <div className="col-md-offset-2 col-md-8">
                    <div className="group">
                       <form onSubmit={handleFormSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>பெயர்</label>
                                        <input type="text" className="form-control" onChange={onChange} id="name" style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}}  name="data[Contact][name]" value={name} onkeypress="javascript:convertThis(event);" />                        
                                    </div>
                                    <div className="form-group">
                                            <label>இ-மெயில் </label>
                                        {/* <input type="email" className="form-control" id="email" style={{ padding:"22px", fontSize:"16px"}} placeholder="Your email" name="data[Contact][email]" /> */}

                                        <input type="text" className="form-control" name="data[Contact][email]" onChange={onChange} id="email"  style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={email} onkeypress="javascript:convertThis(event);" />
                                    </div>
                                    <div className="form-group">
                                        <label>பொருள்</label>
                                        {/* <input type="text" className="form-control" id="subject" style={{ padding:"22px", fontSize:"16px"}} placeholder="subject" name="data[Contact][subject]" /> */}
                                        <input type="text" className="form-control" name="data[Contact][subject]" onChange={onChange} id="subject"  style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} value={subject} onkeypress="javascript:convertThis(event);" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>செய்தி</label>
                                        {/* <textarea className="form-control" rows={8} id="message" style={{ padding:"22px", fontSize:"16px"}} name="data[Contact][message]" defaultValue={""} /> */}

                                        <textarea className="form-control" rows={8} onChange={onChange} id="message"  style={{ padding:"22px", fontSize:"16px", color:"#000000d4", fontWeight:"700"}} name="data[Contact][message]" value={message} onkeypress="javascript:convertThis(event);" defaultValue={""} />
                                    </div>
                                    </div>
                                 <div style={{ textAlign:"left" }}>   
                                        <Robot /> 
                                    </div>
                                <div className="form-group text-center">
                                    <input type="submit" className="btn btn-success" defaultValue="Submit" name="submit" id="validation-next" />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>













    )
}

export default Contact;




// function App() {
//     const people = [
//       { name: 'chris' },
//       { name: 'nick' }
//     ];
  
//     return (
//       <div>
//         {people.map(person => (
//           <p>{person.name}</p>
//         ))}
//       </div>
//     );
//   }