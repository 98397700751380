import React, { useState, useEffect } from "react";
import { getpayment, createorder, verifyPayment } from "../action/user";
//import ReactHtmlParser from 'react-html-parser'
import { getpackage, getbank } from "./../action/user";
import swal from "sweetalert";

import config from "../../lib/config";
import AOS from "aos";

function Payment() {
  const [payment_image, setimage] = useState();
  const [payment_content, setcontent] = useState();
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  async function displayRazorpay(pamount, packagedetail) {
    // const res = await loadScript(
    //   'https://checkout.razorpay.com/v1/checkout.js'
    // );

    // if (!res) {
    //   alert('Razorpay SDK failed to load. Are you online?');
    //   return;
    // }

    const result = await createorder(pamount * 100);

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    // console.log(result,"resultresultresultresult");
    const { amount, id: id, currency } = result.userValue;

    const options = {
      key: "rzp_test_cUYhkpw3zSu9Jg", // Enter the Key ID generated from the Dashboard
      amount: amount,
      currency: "INR",
      name: "Malligai Matrimony",
      description: "Malligai Matrimony",
      image:
        "https://www.thirumagalmatrimonymadurai.in/app/webroot//images/logo_Final_new.png",
      order_id: id,
      handler: async function (response) {
        // console.log("sattavcvc",response,"response.razorpay_payment_id",response.razorpay_payment_id,"response.razorpay_order_id",response.razorpay_order_id,"response.razorpay_signature",response.razorpay_signature);
        // const data = {
        //   orderCreationId: "order_JAWG8geMxr0j1x",
        //   razorpayPaymentId: response.razorpay_payment_id,
        //   razorpayOrderId: response.razorpay_order_id,
        //   razorpaySignature: response.razorpay_signature,
        // };

        const data = {
          orderID: response.razorpay_order_id,
          transaction: response,
          packagedetail: packagedetail,
        };

        const result = await verifyPayment(data);

        // console.log(result.userValue,"resultresultresultresultresultresult");
        if (result.userValue) {
          swal("Payment Successful", "Your signature is valid", "success");
        } else {
          swal("Oops!", "Your signature is Invalid", "error");
        }

        // alert(result);
      },
      prefill: {
        name: localStorage.name,
        email: "",
        contact: localStorage.phone_number,
      },
      notes: {
        address: "Example Corporate Office",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  const getpaymentData = async () => {
    var test = await getpayment();

    // console.log("image",test);

    let image = config.API + "/images/user/" + test.userValue.image;
    setimage(image);

    setcontent(test.userValue.content);
  };

  useEffect(() => {
    //logout(history)
    getpaymentData();
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
    window.scrollTo({ top: 550, left: 0, behavior: "smooth" });
    // or simply just AOS.init();
    AOS.init({
      // initialise with other settings
      duration: 2000,
    });
  }, []);

  const [banks, setBanks] = useState([]);
  const [packages, setPackages] = useState([]);
  const getPackData = async () => {
    var test = await getbank();
    setBanks(test.userValue);

    //   console.log(test, "twinkle");

    var test = await getpackage();
    setPackages(test.userValue);

    //   console.log(test, "twinkle");
  };

  useEffect(() => {
    //logout(history)
    getPackData();
  }, []);

  return (
    <div>
      <section className="inner-page-banners">
        <img
          className="images img-responsive"
          src={require("../../assests/images/87295banner.png")}
        />
        <div className="clearfix" />
      </section>

      {/*about heading*/}
      {/* <section style={{ boxShadow: '0px 3px 23px 0px rgb(244, 246, 249)' }} id="payment_way">
                <div className="container about-us">
                    <div className="text-center col-md-offset-4 col-md-3">
                        <div id="triangle_left" />
                        <p>Payment Plan </p>
                        <div id="triangle_right" />
                    </div>
                </div>
            </section> */}
      {/*//about heading*/}
      {/*details*/}

      {/* <section className="ourpackages" style={{ background: '#fafbfd', paddingTop: '30px' }} data-aos="fade-up">
                <div className="container">
                    <div className="row">
                        <div className="title">
                            <h2 className="text-center">OUR PACKAGES</h2>
                        </div>
                    </div>
                    <div className="selectpackage" id="selectpackage">
                        <div className="row">
                            {packages.map(post => (
                                <div className="col-md-3 col-sm-6">
                                    <div className="package-boxshadow" data-aos="flip-right">
                                        <div className="silver">
                                            <h2>{post.packagename}</h2>
                                            <div className="pricecircle">
                                                <h4><img style={{ height: '20px' }} src={require('../../assests/images/rupee.png')} /></h4>
                                                <h1 style={{ fontSize: '35px', fontWeight: '800' }}>{post.amount}</h1>
                                                <p>ONLY</p>
                                            </div>
                                            <h5>Number of Profiles</h5>
                                            <h3>{post.count}</h3>
                                            <h4 style={{ marginBottom: '20px' }}>{"Valid for" + " " + post.monthvalidate + " " + "Months"}</h4>
                                            <a className="button" href="#bankdetail">SELECT PACKAGE</a>
                                            
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w3layouts-footerdesign">
                    <div className="footerdesign">
                        <img src="{ require('../../assests/images/bg.png') }" alt="" />
                    </div>
                    <div className="clearfix" />
                </div>
            </section> */}

      <section className="aboutdetails" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="text-center aboutdetail">
              <h2>கட்டண முறை</h2>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-md-6">
                            <img src={payment_image} />
                        </div> */}

            <div className="col-md-12 content">
              <div dangerouslySetInnerHTML={{ __html: payment_content }} />
              {/* <ul>
                                <li>இதில் பதிவு செய்தால் மணமக்களின் இரு வீட்டாரிடமும் நாங்களே நேரடியாக பேசி சர்வீஸ் சார்ஜ் முறையில் திருமணங்களை இறைவன் அருளால் நல்லபடியாக வெகு சிக்கிரமாகவே திருமணங்களை நடத்தி வருகிறோம் ஒரு சில வரன்களுக்கு ஜாதகத்தில் உல்ல கோளாறுகளாலும் தாங்கள் எண்ணம் போல் மணப்பெண்,மணமகன் அமையாத காரணத்தினால் திருமணங்கள் தாமதமாகிறது.</li>
                                <li>எனவே பெற்றோர்களாகிய நீங்கள் பொறுமையுடன் திருமணங்களை முடித்து கொடுக்கும் வரை எங்களது மேட்ரிமோனிக்கு ஒத்துழைப்புத் தரும்மாறு அன்புடன் கேட்டுக்கொள்கிறோம்.</li>
                            </ul> */}
            </div>
          </div>
        </div>
      </section>
      {/*//details*/}
      {/*bank details*/}
      <section className="bankdetail" id="bankdetail">
        <div className="container">
          <div className="bank">
            <div>
              <h3 className="payonline">PAY ONLINE</h3>
              <div className="row">
                {/* <div className="col-md-6">
                  
                    <img className="logo" src={require('../../assests/images/gp (1).png')} />
                    <p className="pno">Phone Number :  99 4331 5331</p>
                    <div>
                      <h1>QR Code</h1>
                      <img className="logo qrscan" src={require('../../assests/images/qr (1).jpeg')} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <img className="logo" src={require('../../assests/images/phpay (2).png')} />
                    <p className="pno">Phone Number :  99 4331 5331</p>
                    <div>
                      <h1>QR Code</h1>
                      <img className="logo qrscan" src={require('../../assests/images/qr (1).jpeg')} />
                    </div>
                  </div> */}
                {/* <div className="col-md-6">
                                    <div className="yeah">
                                        <img className="logo" src={require('../../assests/images/gp (1).png')} />
                                        <img className="logo" src={require('../../assests/images/phpay (2).png')} />
                                        <p className="pno">Phone Number :  99 4331 5331</p>
                                        <p className="pno">Phone Number :  98 4213 4672</p>
                                        <p className="pno">Phone Number :  82 2020 4747</p>

                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div>
                                        <h1>QR Code</h1>
                                        <img className="logo qrscan" src={require('../../assests/images/malligaipay.jpeg')} />
                                    </div>
                                </div> */}
              </div>
            </div>
            {/* <div className="row">
               
                <h3 className="payonline">PAY ONLINE
                </h3>
              
                <div className="img-pay" ><span ><img className="img-go" src={require('../../assests/images/Goog-Pay.jpg')} style={{ height: '25px' }} /></span><span className="wwww">  Google Pay : {banks.length&&banks[0].bankpno}</span></div>
                <div className="img-pay"><span className="img-come"><img src={require('../../assests/images/phonepe-logo.png')} style={{ height: '25px' }} /></span><span className="wwee">  Phone Pay : {banks.length&&banks[0].bankpno}</span></div>
              </div> */}
            {/* <div className="gpay-head">
                            <img src={require('../../assests/images/gp (1).png')} alt="GPay" />
                        </div> */}
            {/* <div className="col-md-12 row account">
                            <div className="col-md-6 bankdetails d-flex flex-column justify-content-space-evenly">
                                <h1 className="text-center">நீங்கள் நேரடியாக பணத்தை வங்கியிலும் செலுத்தலாம்.</h1>
                                <h3 className="text-center">Bank Account Details</h3>
                                <div className="row">
                                    {banks.length && banks.map(el => (
                                        <div className="col-md-6 col-sm-10 mx-auto">
                                            <div className="item">
                                                <div className="card">
                                                    <div className="content ">
                                                        <p><b>BANK</b><span className="dot">:</span><span className="a1a1">{el.bankname}</span></p>
                                                        <p><b>A/C NAME </b><span className="dot">:</span><span className="a1a1"> {el.accountname}</span></p>
                                                        <p><b>A/C NO </b><span className="dot">:</span><span className="a1a1"> {el.accountno}</span></p>
                                                        <p><b>BRANCH</b><span className="dot">:</span><span className="a1a1" style={{ fontSize: "14px" }}> {el.branchname}</span></p>
                                                        <p><b>IFSC CODE</b><span className="dot">:</span><span className="a1a1"> {el.ifsc_no}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                               
                                <img className="logo qrscan" src={require('../../assests/images/malligaipay.jpeg')} alt="QR Code" />
                            </div>
                        </div> */}

            <div className="container account">
              <h1
                className="text-center"
                style={{ marginTop: "20px", marginBottom: "20px" }}
              >
                நீங்கள் நேரடியாக பணத்தை வங்கியிலும் செலுத்தலாம்.
              </h1>
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xl-4">
                  {banks.length &&
                    banks.map((el) => (
                      <div className="col-md-6 col-sm-10 mx-auto">
                        <div className="item">
                          <div
                            className="card"
                            style={{
                              width: "321px",
                              marginLeft: "40px",
                              height: "300px",
                            }}
                          >
                            <div
                              className="content  custome-content"
                              style={{ lineHeight: "30px" }}
                            >
                              <p>
                                <b>BANK</b>
                                <span className="dot">:</span>
                                <span className="a1a1">{el.bankname}</span>
                              </p>
                              <p>
                                <b>A/C NAME </b>
                                <span className="dot">:</span>
                                <span className="a1a1"> {el.accountname}</span>
                              </p>
                              <p>
                                <b>A/C NO </b>
                                <span className="dot">:</span>
                                <span className="a1a1"> {el.accountno}</span>
                              </p>
                              <p>
                                <b>BRANCH</b>
                                <span className="dot">:</span>
                                <span
                                  className="a1a1"
                                  style={{ fontSize: "14px" }}
                                >
                                  {" "}
                                  {el.branchname}
                                </span>
                              </p>
                              <p>
                                <b>IFSC CODE</b>
                                <span className="dot">:</span>
                                <span className="a1a1"> {el.ifsc_no}</span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xl-4">
                  <img
                    src={require("../../assests/images/phonepay2.jpg")}
                    height={300}
                    style={{ marginTop: "20px" }}
                  />
                </div>
                <div className="col-lg-4">
                  {" "}
                  <img
                    className="logo"
                    src={require("../../assests/images/malligaipay.jpeg")}
                    alt="QR Code"
                    height={300}
                    style={{ marginTop: "20px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section class="after-payment" data-aos="fade-zoom-in">
                <div className="container">
                    <p>Payment can be made in cash or through online money transfer. Please let us know the name of the branch where the payment was made or the REFFERENCE NUMBER of the money transfer after payment</p>
                    <h5>Send Maniyadar to the following address </h5>
                    <div className="money-order text-center">
                        <p /><pre /><p />
                    </div>
                </div>
            </section> */}
    </div>
  );
}

export default Payment;
